import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import videojs, { VideoJsPlayer } from 'video.js';
import classNames from 'classnames';

import styles from './index.module.scss';
// import 'components/Content/VideoPlayer/LoadingSpinner/LoadingSpinner.css'

const vjsComponent = videojs.getComponent('Component');

type LoadingSpinnerProps = {
  player: VideoJsPlayer;
};
type LoadingSpinnerState = {
  waiting: boolean;
};
class LoadingSpinner extends Component<LoadingSpinnerProps, LoadingSpinnerState> {
  stoppedAt: number | null;

  reportStallingTimer: NodeJS.Timeout | null;

  showLoaderTimer: NodeJS.Timeout | null;

  constructor(props: LoadingSpinnerProps) {
    super(props);
    this.stoppedAt = null;
    this.reportStallingTimer = null;
    this.showLoaderTimer = null;
    this.state = {
      waiting: false,
    };
    this.onCanPlayThrough = this.onCanPlayThrough.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.showLoader = this.showLoader.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
  }

  componentWillUnmount() {
    const { player } = this.props;
    player.off('waiting', this.showLoader);
    player.off('canplaythrough', this.onCanPlayThrough);
    player.off('pause', this.hideLoader);
    player.off('timeupdate', this.onTimeUpdate);
  }

  showLoader() {
    const { player } = this.props;
    this.stoppedAt = player.currentTime();
    this.showLoaderTimer = setTimeout(() => {
      this.setState({
        waiting: true,
      });
    }, 1000);
  }

  hideLoader() {
    this.setState({
      waiting: false,
    });

    if (this.showLoaderTimer) {
      clearTimeout(this.showLoaderTimer);
    }
  }

  onCanPlayThrough() {
    const { player } = this.props;
    // First Loading
    if (player.currentTime() === 0) {
      this.hideLoader();
    }
  }

  onTimeUpdate() {
    const { player } = this.props;

    if (this.reportStallingTimer) {
      clearTimeout(this.reportStallingTimer);
    }
    const reportStalling = () => {
      if (!player.paused()) {
        this.showLoader();
      }
    };

    if (this.state.waiting && this.stoppedAt !== player.currentTime()) {
      this.hideLoader();
    }
    this.reportStallingTimer = setTimeout(reportStalling, 1000);
  }

  componentDidMount() {
    const { player } = this.props;
    player.on('waiting', this.showLoader);
    player.on('canplaythrough', this.onCanPlayThrough);
    player.on('pause', this.hideLoader);
    player.on('timeupdate', this.onTimeUpdate);
  }

  render() {
    return (
      <img
        className={classNames(
          styles.loadingSpinner,
          this.state.waiting ? styles.visible : styles.hidden,
        )}
        src="/loader.svg"
      />
    );
  }
}

class VJSLoadingSpinner extends vjsComponent {
  constructor(player: VideoJsPlayer, options: videojs.ComponentOptions) {
    super(player, options);
    this.mount = this.mount.bind(this);
    player.ready(() => {
      this.mount();
    });

    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    ReactDOM.render(<LoadingSpinner player={this.player()} />, this.el());
  }
}

vjsComponent.registerComponent('LoadingSpinner', VJSLoadingSpinner);
