import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import videojs, { VideoJsPlayer } from 'video.js';

import { videoPlayer as videoPlayerDomIds } from 'shared/domIds';
import settingsIcon from 'shared/icons/settings.svg';
import QualityMenu from './QualityMenu';
import CaptionsMenu from './CaptionsMenu';

import styles from './index.module.scss';

const vjsComponent = videojs.getComponent('Component');

type SettingsButtonProps = {
  container: Element;
  player: VideoJsPlayer;
};
export const SettingsButton = ({ container, player }: SettingsButtonProps) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [hidden, setHidden] = useState(true);

  if (container && container.classList) {
    container.id = videoPlayerDomIds.SETTINGS_BUTTON;
    if (!container.classList.contains(styles.root)) {
      container.classList.add(styles.root);
    }
    if (hidden && !container.classList.contains(styles.hidden)) {
      container.classList.add(styles.hidden);
    } else if (!hidden) {
      container.classList.remove(styles.hidden);
    }
  }

  const onMenuSubtreeModified = () => {
    const menuIsEmpty = menuRef.current?.children.length === 0;
    setHidden(menuIsEmpty);
  };

  useEffect(() => {
    menuRef.current?.addEventListener('DOMSubtreeModified', onMenuSubtreeModified);
    return () => menuRef.current?.removeEventListener('DOMSubtreeModified', onMenuSubtreeModified);
  }, []);

  return (
    <React.Fragment>
      <img src={settingsIcon.src} />
      <div id={videoPlayerDomIds.SETTINGS_LIST} ref={menuRef} className={styles.settingsMenu}>
        <QualityMenu player={player} />
        <CaptionsMenu player={player} />
      </div>
    </React.Fragment>
  );
};

class VJSSettingsButton extends vjsComponent {
  constructor(player: VideoJsPlayer, options = {}) {
    super(player, options);
    this.mount = this.mount.bind(this);
    player.ready(() => {
      this.mount();
    });
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    // this.el is an empty div.
    ReactDOM.render(<SettingsButton player={this.player()} container={this.el()} />, this.el());
  }
}

vjsComponent.registerComponent('settingsButton', VJSSettingsButton);

export default VJSSettingsButton;
