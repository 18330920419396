import React from 'react';
import App, { AppContext } from 'next/app';
import { withRouter } from 'next/router';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import Script from 'next/script';
import Head from 'next/head';

import * as Sentry from '@sentry/node';
import { withApollo } from 'shared/apollo';
import { init as GAInit } from 'shared/ga';
import { withCookies } from 'shared/cookie';
import { config } from 'shared/config';

// TODO modularize these styles
import 'components/VideoPlayer/index.scss';
import 'components/VideoPlayer/Controls/FullscreenButton/index.scss';
import 'components/VideoPlayer/Controls/PlayButton/index.scss';

import WatchPage from './watch/[slug]/[id].page';
import DonationPromptBanner from 'components/DonationPromptBanner';
import { withDonationBannerContext } from 'shared/donationBannerContext';
import DonationPromptModal from 'components/DonationPromptModal';

Sentry.init({
  environment: config.appEnv,
  enabled: config.appEnv === 'production',
  dsn: 'https://3e34294a8247485e8ac325034b05ac8d@o369593.ingest.sentry.io/5193869',
});

type AppProps = {
  store: any;
  cookies: { [name: string]: string };
  noStylesGeneration: boolean;
  err: Error;
};

class TheRecountApp extends App<AppProps> {
  componentDidMount() {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }
    if (!window.ANALYTICS_INITIALIZED) {
      if (config.analyticsEnabled) {
        GAInit();
      }
      window.ANALYTICS_INITIALIZED = true;
    }
  }

  static getInitialProps(appCtx: AppContext) {
    appCtx.ctx.res?.setHeader(
      'Cache-Control',
      's-maxage=60, stale-while-revalidate=120, stale-if-error=86400',
    );
    return App.getInitialProps(appCtx);
  }

  render() {
    const { Component, pageProps, router } = this.props;
    const { pathname } = router;
    const pattern = /\/amp\//;
    const isAmp = pattern.test(pathname);

    // Sentry Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props;
    const modifiedPageProps = { ...pageProps, err };
    const ga = (
      <Script>{`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${config.gtmAuth}&gtm_preview=${config.gtmPreview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${config.gtmId}');
      `}</Script>
    );

    const gpt = !isAmp && (
      <Head>
        <script
          key="gptscript"
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
          async={true}
        />
      </Head>
    );

    // eslint-disable-next-line global-require
    require('shared/styles/global.scss');

    if (typeof window === 'undefined') {
      return (
        <StaticRouter location={router.asPath}>
          {!isAmp && ga}
          {gpt}
          <Routes>
            <Route path="*" element={<Component {...modifiedPageProps} />} />
          </Routes>
          <DonationPromptBanner />
        </StaticRouter>
      );
    }
    return (
      <BrowserRouter>
        {ga}
        {gpt}
        <Routes>
          <Route path="/watch/:slug/:id" element={<WatchPage />} />
          <Route path="*" element={<Component {...modifiedPageProps} />} />
        </Routes>
        <DonationPromptModal />
      </BrowserRouter>
    );
  }
}

export default withApollo(withCookies(withDonationBannerContext(withRouter(TheRecountApp) as any)));
