import { UNSAFE_stringToId } from 'types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getEnv = (key: string): string => {
  const value = process.env[key];
  if (value === undefined) {
    throw new Error(`Could not find "${key}" in environment.`);
  }
  return value;
};

const config = {
  wireShow: UNSAFE_stringToId('1'),
  websiteHost: process.env.NEXT_PUBLIC_WEBSITE_HOST,
  graphqlEnpoint: process.env.NEXT_PUBLIC_API_GRAPHQL_ENDPOINT,
  publicApiEnpoint: process.env.NEXT_PUBLIC_API_PUBLIC_ENDPOINT,
  videoAdUrl: process.env.NEXT_PUBLIC_VIDEO_AD_URL,
  adsEnabled: process.env.NEXT_PUBLIC_ADS_ENABLED === 'true',
  analyticsEnabled: process.env.NEXT_PUBLIC_ANALYTICS_ENABLED === 'true',
  gaId: process.env.NEXT_PUBLIC_GA4_ID,
  gtmAuth: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH,
  gtmPreview: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_PREVIEW,
  gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  appEnv: ((): 'production' | 'staging' | 'development' => {
    switch (process.env.GOOGLE_CLOUD_PROJECT) {
      case 'recount-services':
        return 'production';
      case 'recount-services-stage':
        return 'staging';
      default:
        return 'development';
    }
  })(),
  stripePublishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  stripeSecretKey: process.env.STRIPE_SECRET_KEY,
  stripePriceId: process.env.STRIPE_PRICE_ID,
  donationBannerDismissedKey: 'donationBannerDismissed',
  youtubeApiKey: 'AIzaSyDAyw1G8fWBE2CWXyFPjaWYH5Hys1Zvvrg',
  beehiivApiKey: 'vXgogIqKq1wnbSdmYna0Z6i9PpEDhttIJYLQHjWOQr2aAH6CslaV6SNE4TgudHdv',
  beehiivPublicationId: 'pub_7d4db987-632e-4570-ab6c-96f3e3cf8013',
};

export { config };
