import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { Section } from 'types';

import { section as sectionFragment } from './fragments';
import { Loadable, Loaded, NotFound, NotLoaded } from './util';

const sectionsQuery = gql`
  query AllSections {
    sections {
      ${sectionFragment}
    }
  }
`;

const getBySlug = (slug: string): Loadable<Section> => {
  const all = getAll();
  return Loadable.flatMap(all, (sections) => {
    const section = sections.find((s) => s.slug === slug);
    if (section) {
      return Loaded(section);
    }
    return NotFound;
  });
};

const getAll = (): Loadable<Array<Section>> => {
  const { data } = useQuery(sectionsQuery, {
    fetchPolicy: 'cache-first',
  });
  if (data === undefined) {
    return NotLoaded;
  }
  return Loaded(data.sections);
};

export { getAll, getBySlug };
