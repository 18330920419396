import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { Id, LiveVideo, Section, Video } from 'types';

import {
  video as videoFragment,
  liveVideo as liveVideoFragment,
  section as sectionFragment,
} from './fragments';
import { Loadable, Loaded, NotFound, NotLoaded } from './util';

type FullVideo = Video<'tags' | 'show' | 'renditions' | 'sections'>;

const latestBySection = (
  limit: number,
): Loadable<Array<{ section: Section; videos: Array<FullVideo> }>> => {
  const { data } = useQuery(
    gql`
    query LatestVideosBySection($limit: Int!) {
      latestVideosBySection(limit: $limit) {
        section {
          ${sectionFragment}
        }
        videos {
          ${videoFragment}
        }
      }
    }
  `,
    {
      variables: { limit },
    },
  );
  if (data !== undefined) {
    return Loaded(data.latestVideosBySection);
  }
  return NotLoaded;
};

const getBySection = ({
  section,
  offset,
  limit,
}: {
  section: string | Loadable<string>;
  offset?: number;
  limit?: number;
}): Loadable<Array<FullVideo>> => {
  const slug = (() => {
    if (Loadable.isLoadable(section)) {
      if (section._tag === 'Loaded') {
        return section.data;
      }
      return null;
    }
    return section;
  })();
  const { data, loading } = useQuery(
    gql`
    query VideosBySection($section: String!, $offset: Int, $limit: Int) {
      items(section: $section, offset: $offset, limit: $limit) {
        ... on Video {
          ${videoFragment}
        }
      }
    }
  `,
    {
      skip: slug === null,
      variables: { section: slug, offset, limit },
    },
  );
  if (Loadable.isLoadable(section) && section._tag === 'NotFound') {
    return NotFound;
  }
  if (slug === null || loading) {
    return NotLoaded;
  }
  return Loaded(data.items);
};

const getByShow = ({
  show,
  offset,
  limit,
}: {
  show: Id<'Show'> | Loadable<Id<'Show'>>;
  offset?: number;
  limit?: number;
}): Loadable<Array<FullVideo>> => {
  const id = (() => {
    if (Loadable.isLoadable(show)) {
      if (show._tag === 'Loaded') {
        return show.data;
      }
      return null;
    }
    return show;
  })();
  const { data } = useQuery(
    gql`
    query VideosByShow($show: String!, $offset: Int, $limit: Int!) {
      items(showId: $show, offset: $offset, limit: $limit) {
        ... on Video {
          ${videoFragment}
        }
      }
    }
  `,
    {
      skip: id === null,
      variables: { show: id, limit, offset },
    },
  );
  if (Loadable.isLoadable(show) && show._tag === 'NotFound') {
    return NotFound;
  }
  if (data !== undefined) {
    return Loaded(data.items);
  }
  return NotLoaded;
};

const getWireVideos = ({
  offset,
  limit,
}: {
  offset?: number;
  limit: number;
}): Loadable<Array<LiveVideo>> => {
  const { data } = useQuery(
    gql`
    query LiveVideos($offset: Int, $limit: Int) {
      live(offset: $offset, limit: $limit) {
        ${liveVideoFragment}
      }
    }`,
    {
      variables: { offset, limit },
    },
  );
  if (data !== undefined && data.live !== undefined) {
    return Loaded(data.live);
  }
  return NotLoaded;
};

const get = (video: Id<'Video'>): Loadable<FullVideo> => {
  const { data } = useQuery(
    gql`
    query Video($video: String!) {
      item(id: $video) {
        ... on Video {
          ${videoFragment}
        }
      }
    }
  `,
    {
      variables: { video },
    },
  );
  if (data !== undefined) {
    if (data.item === null) {
      return NotFound;
    }
    return Loaded(data.item);
  }
  return NotLoaded;
};

const getPinned = (): Loadable<FullVideo | null> => {
  const { data } = useQuery(
    gql`
    query Video {
      pinnedVideo {
        ... on Video {
          ${videoFragment}
        }
      }
    }
  `,
    {
      variables: {},
    },
  );
  if (data !== undefined) {
    return Loaded(data.pinnedVideo || null);
  }
  return NotLoaded;
};

export { latestBySection, get, getByShow, getBySection, getPinned, getWireVideos };
