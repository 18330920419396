import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import videojs, { VideoJsPlayer, VideoJsPlayButtonClass } from 'video.js';
import classNames from 'classnames';
import useMediaQuery from 'shared/useMediaQuery';

import { videoPlayer as videoPlayerDomIds } from 'shared/domIds';
import PlayButton from './PlayButton';
import FullscreenButton from './FullscreenButton';
import VJSSettingsButton from './SettingsButton';

import styles from './index.module.scss';

const vjsComponent = videojs.getComponent('Component');

const PlayToggle = videojs.getComponent('playToggle') as unknown as VideoJsPlayButtonClass;
const VolumePanel = videojs.getComponent('volumePanel');
const ProgressControl = videojs.getComponent('progressControl');
const RemainingTimeDisplay = videojs.getComponent('remainingTimeDisplay');
const FullscreenToggle = videojs.getComponent(
  'fullscreenToggle',
) as unknown as typeof videojs.FullscreenToggle;

const CONTROL_INACTIVITY_THRESHOLD = 1500; // in milliseconds.

type ControlsProps = {
  container: Element;
  player: VideoJsPlayer;
};
const Controls = ({ player, container }: ControlsProps) => {
  const [tapCoverEnabled, setTapCoverEnabled] = useState(false); // init in false cause the first play doesn't trigger useractive
  const isMobile = useMediaQuery(600);

  const playToggleRef = useRef<videojs.Component>();

  // DOM element refs
  const upperControlsRef = useRef<HTMLDivElement>(null);
  const upperControlsSeparatorRef = useRef<HTMLDivElement>(null);
  const middleControls = useRef<HTMLDivElement>(null);
  const bottomControls = useRef<HTMLDivElement>(null);
  const bottomControlsSeparatorRef = useRef<HTMLDivElement>(null);
  const progressBar = useRef<HTMLDivElement>(null);
  const tapCoverRef = useRef<HTMLDivElement>(null);

  // Tap Cover Refs
  const userRecentlyActivatedControls = useRef(false);
  const activityTimeout = useRef<NodeJS.Timeout | null>(null);

  // Inactivity refs
  const mouseMovedTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  if (container && container.classList) {
    // add our root style to our parent designated container div.
    if (!container.classList.contains(styles.root)) {
      container.classList.add(styles.root);
    }
  }

  const togglePlay = () => {
    if (!isMobile && playToggleRef.current) {
      (playToggleRef.current.el() as HTMLElement).click();
    }
  };
  const toggleControls = () => {
    if (!userRecentlyActivatedControls.current) {
      if (activityTimeout.current) {
        clearTimeout(activityTimeout.current);
      }
      if (player.userActive()) {
        if (!player.paused()) {
          // when it's paused the controls are always on top so we keep it disabled.
          setTapCoverEnabled(true);
        }
        player.userActive(false);
      }
    }
  };
  const createInactivityTimeout = () => {
    if (mouseMovedTimeoutRef.current) {
      clearTimeout(mouseMovedTimeoutRef.current);
    }
    mouseMovedTimeoutRef.current = setTimeout(() => {
      toggleControls();
      mouseMovedTimeoutRef.current = null;
    }, CONTROL_INACTIVITY_THRESHOLD);
  };

  // componentDidMount
  useEffect(() => {
    player.on('spacebarpressed', () => {
      createInactivityTimeout();
    });

    player.on(['play', 'pause'], createInactivityTimeout);

    return () => {
      player.off(['play', 'pause'], createInactivityTimeout);
      player.off('spacebarpressed');
    };
  }, []);

  // componentDidMount and player change
  useEffect(() => {
    const onUserActive = () => {
      // we'll use a timeout since we may get event after the player has already changed to userActive === true
      userRecentlyActivatedControls.current = true;
      if (!player.paused()) {
        // when it's paused the controls are always on top so we keep it disabled.
        setTapCoverEnabled(true);
      }
      if (activityTimeout.current) {
        clearTimeout(activityTimeout.current);
      }
      activityTimeout.current = setTimeout(() => {
        userRecentlyActivatedControls.current = false;
        setTapCoverEnabled(false);
      }, 500);
    };
    const onUserInactive = () => {
      if (!player.paused()) {
        // when it's paused the controls are always on top so we keep it disabled.
        setTapCoverEnabled(true);
      }
    };
    player.on('useractive', onUserActive);
    player.on('userinactive', onUserInactive);

    if (player) {
      player.ready(() => {
        const mPlayToggle = new PlayToggle(player);
        const dPlayToggle = new PlayToggle(player);
        playToggleRef.current = dPlayToggle;
        const settingsButton = new VJSSettingsButton(player);
        const volumePanel = new VolumePanel(player);
        const progressControl = new ProgressControl(player);
        const remainingTimeDisplay = new RemainingTimeDisplay(player);
        const fullscreenToggle: videojs.FullscreenToggle = new FullscreenToggle(player);

        const mobileFullscreenBtn = new FullscreenButton(player, {
          fullscreenToggle,
          isMobile: true,
          id: videoPlayerDomIds.MOBILE_FULLSCREEN_BUTTON,
        });
        const mobilePlayButton = new PlayButton(player, {
          playToggle: mPlayToggle,
          isMobile: true,
        });

        const desktopFullscreenBtn = new FullscreenButton(player, {
          fullscreenToggle,
          isMobile: false,
          id: videoPlayerDomIds.DESKTOP_FULLSCREEN_BUTTON,
        });
        const desktopPlayButton = new PlayButton(player, {
          playToggle: dPlayToggle,
          isMobile: false,
        });

        if (upperControlsRef.current) {
          // buttons need to be backwards here
          const volumePanelBtn = volumePanel.el();
          volumePanelBtn.id = videoPlayerDomIds.VOLUME_PANEL;

          upperControlsRef.current.insertBefore(
            settingsButton.el(),
            upperControlsSeparatorRef.current,
          );
          upperControlsRef.current.insertBefore(volumePanelBtn, upperControlsSeparatorRef.current);

          upperControlsRef.current.appendChild(desktopFullscreenBtn.el());
        }
        if (middleControls.current) {
          middleControls.current.appendChild(mobilePlayButton.el());
        }
        if (bottomControls.current) {
          // mobile
          bottomControls.current.insertBefore(
            desktopPlayButton.el(),
            bottomControlsSeparatorRef.current,
          );

          const remainingTimeDisplayLabel = remainingTimeDisplay.el();
          remainingTimeDisplayLabel.id = videoPlayerDomIds.REMIAING_TIME;

          // desktop
          bottomControls.current.insertBefore(
            mobileFullscreenBtn.el(),
            bottomControlsSeparatorRef.current,
          );

          // common
          bottomControls.current.appendChild(remainingTimeDisplayLabel);
        }
        if (progressBar.current) {
          progressBar.current.appendChild(progressControl.el());
        }
      });
    }

    return () => {
      player.off('useractive', onUserActive);
      player.off('userinactive', onUserInactive);
    };
  }, [player]);
  return (
    <React.Fragment>
      <div
        className={styles.background}
        onMouseEnter={createInactivityTimeout}
        onMouseMove={createInactivityTimeout}
        onClick={() => {
          togglePlay();
          toggleControls();
        }}
      />
      <div
        ref={upperControlsRef}
        className={`${styles.controlBar} ${styles.upperControlsContainer}`}
        onMouseEnter={createInactivityTimeout}
        onMouseMove={createInactivityTimeout}
      >
        <span ref={upperControlsSeparatorRef} />
      </div>
      <div
        ref={middleControls}
        className={`${styles.controlBar} ${styles.middleControlsContainer}`}
        onMouseEnter={createInactivityTimeout}
        onMouseMove={createInactivityTimeout}
      />
      <div
        ref={bottomControls}
        className={`${styles.controlBar} ${styles.bottomControlsContainer}`}
        onMouseEnter={createInactivityTimeout}
        onMouseMove={createInactivityTimeout}
      >
        <span ref={bottomControlsSeparatorRef} />
      </div>
      <div
        ref={progressBar}
        className={`${styles.controlBar} ${styles.progressBarContainer}`}
        onMouseEnter={createInactivityTimeout}
        onMouseMove={createInactivityTimeout}
      />
      <div
        ref={tapCoverRef}
        className={classNames(styles.tapCover, { [styles.enabled]: tapCoverEnabled })}
        onMouseEnter={createInactivityTimeout}
        onMouseMove={createInactivityTimeout}
        onClick={togglePlay}
      />
    </React.Fragment>
  );
};

class VJSControls extends vjsComponent {
  constructor(player: VideoJsPlayer, options?: videojs.ComponentOptions) {
    super(player, options);
    this.mount = this.mount.bind(this);
    player.ready(() => {
      this.mount();
    });

    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    ReactDOM.render(<Controls player={this.player()} container={this.el()} />, this.el());
  }
}

vjsComponent.registerComponent('customControls', VJSControls);

export default VJSControls;
