import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { LiveVideo, Video } from 'types';
import Icon from 'components/Icon';
import { imageUrl, formatDateTime } from 'shared/util';
import styles from './index.module.scss';

type VideoTileProps = {
  video: Video | LiveVideo;
  reverse?: boolean;
  displayTitle?: boolean;
  displayDate?: boolean;
  className?: string;
  imageWidth: number;
};
const VideoTile: React.FC<VideoTileProps> = ({ video, reverse = false, className, imageWidth }) => {
  const dateClass = reverse ? classNames(styles.videoDate, styles.reverse) : styles.videoDate;
  const metaClass = reverse ? classNames(styles.videoMeta, styles.reverse) : styles.videoMeta;
  const link = `/watch/${video.slug}/${video.id}?autoplay=true`;
  return (
    <Link to={link}>
      <article className={classNames(styles.videoTileContainer, className)}>
        <span className={styles.videoContainer}>
          <span className={styles.playButton}>
            <Icon.Play color="white" />
            <span>{formatDateTime(video.duration, 'M:ss')}</span>
          </span>
          <img
            className={styles.thumbnail}
            src={imageUrl({
              id: video.thumbnailURL,
              width: imageWidth,
              height: Math.ceil((imageWidth * 9) / 16),
            })}
            alt=""
          />
        </span>
        <span className={metaClass}>
          <p className={dateClass}>{formatDateTime(video.publishedAt, 'dddd mm.dd.yy')}</p>
          <p>{video.title}</p>
        </span>
        <hr className={classNames(styles.tileDivider, 'hide@md', 'hide@lg')} />
      </article>
    </Link>
  );
};

export default VideoTile;
