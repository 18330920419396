import { useState } from 'react';
import Clipboard from 'react-clipboard.js';
import classNames from 'classnames/bind';

import Icon from 'components/Icon';
import styles from './index.module.scss';

interface CopyLinkButtonProps {
  clipboardText: string;
  successText?: string;
  disabled?: boolean;
}

const CopyLinkButton = ({
  clipboardText,
  successText = 'Copied!',
  disabled = false,
}: CopyLinkButtonProps) => {
  const [tooltipTimer, setTooltipTimer] = useState<NodeJS.Timeout | null>(null);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const activateCopyTooltip = () => {
    if (tooltipTimer) {
      clearTimeout(tooltipTimer);
    }

    setShowCopyTooltip(true);
    setTooltipTimer(
      setTimeout(() => {
        setShowCopyTooltip(false);
      }, 1000),
    );
  };

  const icon = (
    <>
      <span className={styles.iconLink} aria-label="Copy page link to clipboard (disabled)">
        <Icon.Link color="" />
      </span>

      <span className={classNames(styles.tooltip, showCopyTooltip ? styles.visible : '')}>
        <span>{successText}</span>
      </span>
    </>
  );
  return disabled ? (
    <button className={styles.clipboardButton}>{icon}</button>
  ) : (
    <Clipboard
      className={styles.clipboardButton}
      data-clipboard-text={clipboardText}
      onSuccess={activateCopyTooltip}
      aria-label="Copy page link to clipboard"
    >
      {icon}
    </Clipboard>
  );
};

export default CopyLinkButton;
