import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import type { NextPageContext } from 'next';
import { config } from './config';
import { stripeTip } from 'store/stripe-tip';
import { logEvent } from './ga';
import { useRouter } from 'next/router';

// we want to show the donation banner when one of the following conditions is met:
// 1. initial scroll
// 2. video play event
// 3. 10 seconds after page load

// We either show the banner or the Modal
// once the modal has been dismissed on route change/refresh we show the banner

interface DonationBannerManager {
  isBannerDismissed: boolean;
  setIsBannerDismissed: Dispatch<SetStateAction<boolean>>;
  showBanner: boolean;
  showDialogue: () => void;
  closeDialogue: () => void;
  routeHasChanged: boolean;
  setRouteHasChanged: Dispatch<SetStateAction<boolean>>;
  handleDonate: () => void;
}

const DonationBannerContext = React.createContext<DonationBannerManager | null>(null);

type NextComponent<T> = React.ComponentType<T> & {
  getInitialProps?: (args: { ctx?: NextPageContext }) => Promise<T>;
};
const withDonationBannerContext = <T,>(WrappedComponent: NextComponent<T>): NextComponent<T> => {
  const DonationBannerProvider: any = (props: any) => {
    const router = useRouter();
    const [isBannerDismissed, setIsBannerDismissed] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [routeHasChanged, setRouteHasChanged] = useState(isBannerDismissed);

    let timeoutId: NodeJS.Timeout | null = null;

    const showDialogue = () => {
      if (timeoutId) clearTimeout(timeoutId);
      if (isBannerDismissed) return;
      setShowBanner(true);
    };

    const closeDialogue = () => {
      setShowBanner(false);
      setIsBannerDismissed(true);
      sessionStorage.setItem(config.donationBannerDismissedKey, 'true');
    };

    const handleScroll = () => showDialogue();

    useEffect(() => {
      const isSessionStorageAvailable = typeof window !== 'undefined' && window.sessionStorage;
      if (isSessionStorageAvailable) {
        const dismissed = sessionStorage.getItem(config.donationBannerDismissedKey);
        setIsBannerDismissed(dismissed === 'true');
        setRouteHasChanged(dismissed === 'true');
      }
    }, []);

    useEffect(() => {
      timeoutId = setTimeout(showDialogue, 1000 * 10);
      return () => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = null;
      };
    }, []);

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const handleDonate = async () => {
      logEvent({
        action: 'begin_checkout'
      });
      const redirectUrl = `${window.location.origin}${window.location.pathname}?moduleId=donate-end`;
      await stripeTip(redirectUrl);
    };

    useEffect(() => {
      if (router.query.paymentStatus && router.query.moduleId) {
        if (router.query.paymentStatus === 'success') logEvent({ action: 'purchase' });
        // clear router query params
        router.replace(
          {
            pathname: router.pathname,
            query: {},
          },
          undefined,
          {
            shallow: true,
          },
        );
      }
    }, [router.query]);

    const donationBannerContextValue: DonationBannerManager = {
      isBannerDismissed,
      setIsBannerDismissed,
      showBanner,
      showDialogue,
      closeDialogue,
      routeHasChanged,
      setRouteHasChanged,
      handleDonate,
    };

    return (
      <DonationBannerContext.Provider value={donationBannerContextValue}>
        <WrappedComponent {...props} />
      </DonationBannerContext.Provider>
    );
  };

  return DonationBannerProvider;
};

const useDonationBannerContext = () => {
  const context = React.useContext(DonationBannerContext);
  if (!context) {
    throw new Error('useDonationBannerContext must be used within a DonationBannerProvider');
  }
  return context;
};
export { withDonationBannerContext, useDonationBannerContext };
