import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDonationBannerContext } from 'shared/donationBannerContext';
import styles from './index.module.scss';

const DONATION_STRINGS = {
  HEADER: 'The news is broken. Too much blather, bad faith, and BS.',
  SUBHEADER: `At The Recount, we’re reimagining news for the platforms that shape our national conversation: streaming and social media. We reject stale narratives and cut to the chase, driving dialogue instead of division. Featuring voices that matter and holding power to account. Never wasting your time or insulting your intelligence. In an era of information overload, alternative facts, and rampant conspiracy theories, it’s never been more vital to illuminate what matters most: the truth.`,
  SUBHEADER_CAPTION:
    'But we can’t do it alone. We need your help. Empower us and our mission by making a donation today.',
  DONATION_SECTION_HEADER: 'Your contribution, big or small, allows us to:',
  DONATION_SECTION_LIST: [
    {
      HEADER: 'Report Fearlessly',
      SUBHEADER: `We don't shy away from the tough questions, holding those in power accountable and providing critical analysis of the issues that matter most.`,
    },
    {
      HEADER: 'Maintain our editorial independence',
      SUBHEADER:
        'We are not beholden to any special interests or political parties. Your support allows us to remain fiercely independent, providing you with unbiased and trustworthy news.',
    },
    {
      HEADER: 'Innovate and evolve',
      SUBHEADER:
        'The way we consume news is constantly changing; we’re dedicated to staying ahead of the curve. Your contributions ensure we continue to deliver the news in engaging and accessible ways.',
    },
  ],
  DONATION_SECTION_SUBTEXT: [
    `Every dollar makes a difference. Join us in our drive to keep people informed.`,
    `Donate to The Recount today and help us cut through the noise, together.`,
  ],
};

const DonationPromptBanner = () => {
  const { isBannerDismissed, routeHasChanged, setRouteHasChanged, handleDonate } =
    useDonationBannerContext();

  const DonateButton = () => (
    <button className={styles.donationButton} onClick={handleDonate}>
      Donate Now
    </button>
  );

  const DonationCard = ({ title, description }: { title: string; description: string }) => (
    <div className={styles.donationCard}>
      <p className={styles.donationCardTitle}>{title}</p>
      <p className={styles.donationCardDescription}>{description}</p>
    </div>
  );
  const router = useRouter();

  useEffect(() => {
    if (isBannerDismissed) setRouteHasChanged(true);
  }, [router]);

  return (
    <React.Fragment>
      {isBannerDismissed && routeHasChanged ? (
        <div className={styles.container}>
          <div className={styles.donationBody}>
            <div className={styles.donationSubheader}>
              <div className={styles.donationHeader}>
                <p>{DONATION_STRINGS.HEADER}</p>
              </div>
              <div className={styles.donationSubheaderBody}>
                <p>{DONATION_STRINGS.SUBHEADER}</p>
                <p className={styles.donationSubheaderCaption}>
                  {DONATION_STRINGS.SUBHEADER_CAPTION}
                </p>
              </div>
            </div>
            <div className={styles.donationSection}>
              <div className={styles.donationSectionHeader}>
                <p>{DONATION_STRINGS.DONATION_SECTION_HEADER}</p>
              </div>
              <hr className={styles.donationSectionListDivider} />
              <div className={styles.donationSectionList}>
                {DONATION_STRINGS.DONATION_SECTION_LIST.map((item, index) => (
                  <DonationCard key={index} title={item.HEADER} description={item.SUBHEADER} />
                ))}
              </div>
              <hr className={styles.donationSectionListDivider} />
              <div className={styles.donationSubtext}>
                {DONATION_STRINGS.DONATION_SECTION_SUBTEXT.map((item, index) => (
                  <p key={`donation_${index}`}>{item}</p>
                ))}
                <DonateButton />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DonationPromptBanner;
