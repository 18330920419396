import React, { useEffect, useState, useRef } from 'react';
import type videojs from 'video.js';

import CaptionsItem from './CaptionsItem';

import styles from './index.module.scss';

type Mode = 'showing' | 'hidden' | 'disabled';

const kind = 'subtitles';
const language = 'en';
const showing = 'showing';
const hidden = 'hidden';

const onModes = [showing];
const offModes = [hidden, 'disabled'];

const getCaptionsOptions = (currentMode: Mode, onClick: (m: Mode) => void) => {
  const on = 'On';
  const off = 'Off';
  const options = [on, off];

  const jsx = options.map((opt) => {
    const modes = opt === on ? onModes : offModes;
    const mode = opt === on ? showing : hidden;
    const selected = modes.includes(currentMode);
    return (
      <CaptionsItem key={opt} mode={mode} onClick={onClick} selected={selected}>
        {opt}
      </CaptionsItem>
    );
  });

  return jsx;
};

const CaptionsMenu = ({ player }: { player: videojs.Player }) => {
  const [captionsList, setCaptionsList] = useState<Array<TextTrack>>([]);
  const [currentMode, setCurrentMode] = useState<Mode>(hidden);
  const modeRef = useRef(currentMode);

  const onTrackListModified = () => {
    const captions = getTracks();

    if (captions.length > 0) {
      setCaptionsList(captions);
    } else {
      setCaptionsList([]);
    }

    // We do this because we need to allow the TextTrackDisplay to receive the 'texttrackchange' event (a.k.a 'addtrack' & 'removetrack' events)
    // and connect to the track's 'change' event before changing the track's mode, otherwise we'd fire
    // the track's 'change' event before the TextTrackDisplay had a change to connect to it and and the
    // text tracks wouldn't update.
    setTimeout(() => updateTracksMode(), 0);
  };

  const updateTracksMode = (mode?: Mode) => {
    const newMode = mode || modeRef.current;
    const captions = getTracks();
    for (let i = 0; i < captions.length; i += 1) {
      const caption = captions[i];
      if (caption.mode !== newMode) {
        captions[i].mode = newMode;
      }
    }
  };
  const getTracks = () => {
    const tracks = player.textTracks();
    const captions = [];
    for (let i = 0; i < tracks.length; i += 1) {
      const track = tracks[i];
      if (track.kind === kind && track.language === language) {
        captions.push(track);
      }
    }
    return captions;
  };

  const onItemClicked = (mode: Mode) => {
    modeRef.current = mode;
    setCurrentMode(mode);
    updateTracksMode(mode);
  };

  useEffect(() => {
    player.textTracks().addEventListener('addtrack', onTrackListModified);
    player.textTracks().addEventListener('removetrack', onTrackListModified);
    return () => {
      player.textTracks().removeEventListener('addtrack', onTrackListModified);
      player.textTracks().removeEventListener('removetrack', onTrackListModified);
    };
  }, []);

  if (captionsList.length === 0) {
    // we won't show the menu item if the player has no captions to show.
    return null;
  }
  return (
    <React.Fragment>
      <div className={styles.separator} />
      <div className={styles.menuTitle}>Captions</div>
      <div className={styles.separator} />
      {getCaptionsOptions(currentMode, onItemClicked)}
    </React.Fragment>
  );
};

export default CaptionsMenu;
