import React from 'react';
import classNames from 'classnames';

import { videoPlayer as videoPlayerDomIds } from 'shared/domIds';

import styles from './index.module.scss';

type Mode = 'showing' | 'hidden' | 'disabled';

type CaptionsItemProps = {
  children: React.ReactNode;
  selected: boolean;
  onClick: (mode: Mode) => void;
  mode: Mode;
};
const CaptionsItem = ({ children, selected, onClick, mode }: CaptionsItemProps) => {
  const onSelect = () => {
    onClick(mode);
  };

  const clazz = classNames(styles.root, videoPlayerDomIds.CAPTIONS_OPTION, {
    [styles.selected]: selected,
  });

  return (
    <div className={clazz} onClick={onSelect}>
      {children}
    </div>
  );
};

export default CaptionsItem;
