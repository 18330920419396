import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { LiveVideo, Newsletter, Video, YouTube } from 'types';
import Icon from 'components/Icon';
import { imageUrl, formatDateTime } from 'shared/util';
import { Skeleton } from 'components/Skeleton';
import Image from 'next/future/image';

import styles from './index.module.scss';
import { Dialog, DialogContent, DialogTrigger } from 'components/DialogComponent';

const useRefDimensions = (ref: React.RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState({ width: 300, height: 169 });

  const calculateDimensions = () => {
    if (ref.current) {
      const boundingRect = ref.current.getBoundingClientRect();
      const { width, height } = boundingRect;
      setDimensions({ width: Math.round(width), height: Math.round(height) });
    }
  };

  useEffect(() => {
    calculateDimensions();

    function handleResize() {
      calculateDimensions();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return dimensions;
};

export enum TILE_TYPE {
  INTERNAL,
  YOUTUBE,
  NEWSLETTER,
}

type VideoTileProps = {
  video: Video | LiveVideo | Newsletter | YouTube;
  reverse?: boolean;
  displayTitle?: boolean;
  className?: string;
  imageWidth: number;
  href?: string;
  externalLink?: boolean;
  type?: TILE_TYPE;
};

function isInternal(video: Video | LiveVideo | Newsletter | YouTube): video is Video | LiveVideo {
  return (video as Video).duration !== undefined && !!(video as Video).slug;
}

const VideoTile: React.FC<VideoTileProps> = ({
  video,
  reverse = false,
  displayTitle = true,
  className,
  imageWidth,
  href,
  externalLink,
  type = TILE_TYPE.INTERNAL,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const dimensions = useRefDimensions(ref);

  const buttonStyles = {
    '--scale': dimensions.width,
    height: 'auto',
  };

  const titleClass = reverse ? classNames(styles.videoTitle, styles.reverse) : styles.videoTitle;

  let link = href || '#';
  if (isInternal(video) && !href) {
    link = `/watch/${video.slug}/${video.id}?autoplay=true`;
  }

  const tile = (
    <div className={classNames(styles.videoTileContainer, className)}>
      <div className={styles.videoContainer}>
        {isInternal(video) && (
          <div ref={ref} className={styles.playButtonContainer}>
            <div className={styles.playButton} style={buttonStyles}>
              <Icon.Play color="white" />
              <span>{formatDateTime(video.duration, 'M:ss')}</span>
            </div>
          </div>
        )}
        <Image
          className={styles.thumbnail}
          src={imageUrl({
            id: video.thumbnailURL,
            width: imageWidth,
            height: Math.ceil((imageWidth * 9) / 16),
          })}
          width={imageWidth}
          height={Math.ceil((imageWidth * 9) / 16)}
          alt={'description' in video ? video.description || 'Latest news' : 'Latest news'}
        />
      </div>
      {displayTitle ? <p className={titleClass}>{video.title}</p> : null}
      {!isInternal(video) && video?.subtitle && (
        <p className={styles.videoSubtitle}>{video.subtitle}</p>
      )}
    </div>
  );

  const getLink = () => {
    if (externalLink) {
      return (
        <a href={link} rel="noreferrer" target={'_blank'}>
          {tile}
        </a>
      );
    }

    return <Link to={link}>{tile}</Link>;
  };

  const getTile = () => {
    if (type === TILE_TYPE.YOUTUBE) {
      return (
        <Dialog key={`video-${video.id}`}>
          <DialogContent className={styles.youtubePlayer}>
            <iframe
              className={styles.youtubePlayerIframe}
              src={`https://www.youtube.com/embed/${video.id}?autoplay=0`}
              title={video.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </DialogContent>
          <DialogTrigger asChild>{getLink()}</DialogTrigger>
        </Dialog>
      );
    }

    return getLink();
  };

  return getTile();
};

const VideoTileSkeleton = () => {
  return (
    <>
      <Skeleton type="image" />
      <Skeleton type="text" />
      <Skeleton type="word" />
    </>
  );
};

export { VideoTile, VideoTileSkeleton };
