import cx from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './index.module.scss';

const isDOMTypeElement = (element: ReactNode) =>
  React.isValidElement(element) && typeof element.type === 'string';

type Columns = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type JustifyContent = 'flex-start' | 'center' | 'space-between' | 'flex-end' | 'space-around';
type AlignContent =
  | 'stretch'
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around';
type AlignItems = 'stretch' | 'flex-start' | 'center' | 'flex-end' | 'baseline';
type Direction = 'row' | 'row-reverse' | 'column' | 'column-reverse';
type Breakpoint = 'sm' | 'md' | 'lg';

/**
 * Grid Component usage.
 *
 * Start with a container
 * `container` prop is optional as it is the default

   <Grid container>
   </Grid>
 
  * Add Grid items using the `item` prop
  * NOTE: if `item` and `container` props are both added, `item` takes precedence
  * 
  * column sizes should equal to 12
  * The following grid will create:
  * Stacked items at 100% width for 768px and below
  * 2 2-column row2 at medium and up
  * 1 4-column row at lg and up
  * 
    <Grid container>
      <Grid item sm={12} md={6} md={3}>
        <!-- content -->
      </Grid>
      <Grid item sm={12} md={6} md={3}>
        <!-- content -->
      </Grid>
      <Grid item sm={12} md={6} md={3}>
        <!-- content -->
      </Grid>
      <Grid item sm={12} md={6} md={3}>
        <!-- content -->
      </Grid>
    </Grid>

  * Example layout with `space-between` and nested Grid container
  *
    <Grid container justifyContent="space-between">
      <Grid item sm={12} md={4}>
        <h3 className="slogan-heading">No bullshit. No bad faith. And no time wasted.</h3>
        <p>Recount Media is a fresh approach to news that shows you what’s happening without insulting your intelligence.</p>
      </Grid>

      <Grid container sm={12} md={6}>
        <Grid item sm={12} md={4}>
          Editorial
        </Grid>
        <Grid item sm={12} md={4}>
          About us
        </Grid>
        <Grid item sm={12} md={4}>
          Social
        </Grid>
      </Grid>
    </Grid>
 * 
 * @param virtual If true Grid will not generate a div, instead applying
 *        the relevant props to its child which must be a single ReactElement.
 */
const Grid: FunctionComponent<
  {
    children: ReactNode;
    container?: boolean;

    full?: boolean;
    item?: boolean;
    nowrap?: boolean | Array<Breakpoint>;
    sm?: Columns;
    md?: Columns;
    lg?: Columns;
    xl?: Columns;
    justifyContent?: JustifyContent;
    alignContent?: AlignContent;
    alignItems?: AlignItems;
    direction?: Direction;
    className?: string;
    gap?: string;
    virtual?: boolean;
    paddingLeft?: string;
    paddingRight?: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  container = true,
  full,
  item,
  nowrap = false,
  sm,
  md,
  lg,
  xl,
  justifyContent,
  alignContent = 'stretch',
  alignItems = 'stretch',
  direction = 'row',
  className,
  gap,
  virtual = false,
  paddingLeft,
  paddingRight,
}) => {
  const classNames = cx({
    [styles.container]: container && !item,

    [styles.item]: item,
    [styles.nowrap]: typeof nowrap === 'boolean' && nowrap,
    [styles.nowrapSm]: Array.isArray(nowrap) && nowrap.includes('sm'),
    [styles.nowrapMd]: Array.isArray(nowrap) && nowrap.includes('md'),
    [styles.nowrapLg]: Array.isArray(nowrap) && nowrap.includes('lg'),
    [styles.full]: full && container,
    [styles[`sm-${sm}`]]: sm,
    [styles[`md-${md}`]]: md,
    [styles[`lg-${lg}`]]: lg,
    [styles[`xl-${xl}`]]: xl,
    [styles[`justifyContent-${justifyContent}`]]: justifyContent,
    [styles[`alignContent-${alignContent}`]]: alignContent,
    [styles[`alignItems-${alignItems}`]]: alignItems,
    [styles[`direction-${direction}`]]: direction,
    [`${className}`]: className,
  });
  const style: { [k: string]: string } = {};
  if (gap !== undefined) {
    style['--gap-size'] = gap;
  }

  if (paddingLeft) {
    style['--padding-left'] = paddingLeft;
  }
  if (paddingRight) {
    style['--padding-right'] = paddingRight;
  }

  if (virtual) {
    if (Array.isArray(children) || !isDOMTypeElement(children)) {
      throw new TypeError('Child of virtual grid must be a single ReactElement');
    }
    const el = children as React.ReactElement;
    return React.cloneElement(el, {
      ...el.props,
      style: { ...style, ...el.props.style },
      className: classNames + (el.props.className ? ` ${el.props.className}` : ''),
    });
  }

  return (
    <div style={style} className={classNames}>
      {children}
    </div>
  );
};

export default Grid;
