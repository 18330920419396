import { Section } from 'types';
import * as sectionStore from 'store/sections';
import { exhaustive } from 'shared/util';
import Grid from 'components/Grid';

import styles from './index.module.scss';

type FooterProps = {
  sections: Array<Section>;
};
const Footer = ({ sections }: FooterProps) => {
  const editorialItems = sections.map((s) => ({
    slug: `/section/${s.slug}`,
    title: s.title,
  }));
  /*
  editorialItems.push({
    slug: '/podcasts',
    title: 'Podcasts',
  });
  */
  return (
    <footer className={styles.root}>
      <Grid container justifyContent="space-between" className={styles.footer}>
        <Grid sm={12} md={5} lg={4} xl={4}>
          <h1>
            No bullshit. No bad faith.
            <br />
            And no time wasted.
          </h1>
          <p>
            Recount Media is a fresh approach to news that shows you what’s happening without
            insulting your intelligence.
          </p>
          <p>
            Recount Media is part of{' '}
            <a href="https://www.thenewsmovement.com/">The News Movement</a> family.
          </p>
          <aside className="hide@sm">
            <a href="/privacy">PRIVACY POLICY</a> | <a href="/terms">TERMS &amp; CONDITIONS</a> ©
            {new Date().getFullYear()} RECOUNT MEDIA
          </aside>
        </Grid>
        <Grid container sm={12} md={6} lg={6} xl={6} justifyContent="space-around">
          <Grid sm={12} md={2} lg={2} xl={2} className={styles.links}>
            <h2>Editorial</h2>
            <ul>
              {editorialItems.map((item) => (
                <li key={item.slug}>
                  <a href={item.slug}>{item.title}</a>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid sm={12} md={2} lg={2} xl={2} className={styles.links}>
            <h2>About Us</h2>
            <ul>
              <li>
                <a href="/about">Team</a>
              </li>
              <li>
                <a href="https://www.squarepeghires.com/jobs/employer/the-recount">Jobs</a>
              </li>
              <li>
                <a href="mailto:partnerwithus@thenewsmovement.com">Advertise</a>
              </li>
              <li>
                <a href="https://www.thenewsmovement.com/">The News Movement</a>
              </li>
            </ul>
          </Grid>
          <Grid sm={12} md={2} lg={2} xl={2} className={styles.links}>
            <h2>Social</h2>
            <ul>
              <li>
                <a href="https://www.instagram.com/TheRecount/">Instagram</a>
              </li>
              <li>
                <a href="https://twitter.com/therecount">Twitter</a>
              </li>
              <li>
                <a href="https://www.facebook.com/TheRecount/">Facebook</a>
              </li>
              <li>
                <a href="https://www.youtube.com/therecount">YouTube</a>
              </li>
              <li>
                <a href="https://www.twitch.tv/therecount">Twitch</a>
              </li>
              <li>
                <a href="https://flipboard.com/@TheRecount">Flipboard</a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};

/** Creates a footer component with sections already applied */
const useFooter = (): React.FunctionComponent<Omit<FooterProps, 'sections'>> => {
  const sections = sectionStore.getAll();

  const curriedFooter = (props: Omit<FooterProps, 'sections'>) => {
    switch (sections._tag) {
      case 'NotLoaded':
      case 'NotFound':
        return null;
      case 'Loaded': {
        return <Footer sections={sections.data} {...props} />;
      }
      default:
        return exhaustive(sections);
    }
  };
  curriedFooter.displayName = 'CurriedFooter';
  return curriedFooter;
};

export { Footer, useFooter };
