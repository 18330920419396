import styles from './index.module.scss';
import Icon from 'components/Icon';
import { useDonationBannerContext } from 'shared/donationBannerContext';

const PAYWALL_HEADER = ['Expecting a paywall?', "That's not our thing."];

const DonationPromptModal = () => {
  const { isBannerDismissed, showBanner, closeDialogue, handleDonate } = useDonationBannerContext();
  const handleClose = () => {
    closeDialogue();
  };

  if (isBannerDismissed) {
    return null;
  }

  return (
    <>
      {showBanner ? (
        <div className={`${styles.donationOverlay}`}>
          <div className={styles.donationBanner}>
            <button className={styles.closeButton} onClick={handleClose}>
              <Icon.CrossIcon color="white" />
            </button>
            <div className={styles.headerContainer}>
              {PAYWALL_HEADER.map((child, i) => (
                <h2 key={`${i}-${child}`} className={styles.paywallHeader}>
                  <strong>{child}</strong>
                </h2>
              ))}
            </div>
            <div className={styles.bodyContainer}>
              <div className={styles.bodyInner}>
                {'At the Recount, we want news to be '}
                <span
                  style={{
                    color: 'var(--brand-primary)',
                  }}
                >
                  {`accessible so everyone can stay informed. `}
                </span>
                But to make that happen, we need your help. Your dollars will go toward our mission
                to cut through the noise, for everyone.
              </div>
              <button className={styles.donateButton} onClick={handleDonate}>
                Donate Now
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DonationPromptModal;
