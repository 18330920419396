import { unstable_batchedUpdates } from 'react-dom';
import { splitAt, reverse } from 'ramda';
import dateFormat from 'dateformat';
import { BlockNode, InlineNode } from 'types';

const padString = (string: string, pad: string, length: number) => {
  return (new Array(length + 1).join(pad) + string).slice(-length);
};

const isExternal = (url: string) => {
  const match = url.match(/^([^:/?#]+:)?(?:\/\/([^/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
  if (
    match != null &&
    typeof match[1] === 'string' &&
    match[1].length > 0 &&
    match[1].toLowerCase() !== window.location.protocol
  )
    return true;
  if (
    match != null &&
    typeof match[2] === 'string' &&
    match[2].length > 0 &&
    match[2].replace(
      new RegExp(`:(${{ 'http:': 80, 'https:': 443 }[window.location.protocol]})?$`),
      '',
    ) !== window.location.host
  ) {
    return true;
  }

  return false;
};

const durationToString = (duration: number) => {
  let remainingSeconds = duration;
  const hours = Math.floor(remainingSeconds / 3600.0);
  remainingSeconds = Math.max(0.0, remainingSeconds - hours * 3600.0);
  const minutes = Math.floor(remainingSeconds / 60.0);
  remainingSeconds = Math.round(Math.max(0.0, remainingSeconds - minutes * 60.0));
  let timeString = '';
  const paddedHours = padString(`${hours}`, '', 2);
  const paddedMinutes = padString(`${minutes}`, '', 2);
  const paddedSeconds = padString(`${remainingSeconds}`, '0', 2);
  if (hours > 0) {
    timeString += `${paddedHours}:`;
  }
  timeString += `${paddedMinutes}:${paddedSeconds}`;
  return timeString;
};

const stringToSlug = (str: string) => {
  let slug = str.replace(/^\s+|\s+$/g, '');
  slug = slug.toLowerCase();

  // handle special characters
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  slug = slug
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return slug.toLowerCase();
};

const setCookie = (name: string, value: string, exdays?: number, path?: string) => {
  const days = exdays || 7;
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  let cookie = `${name}=${value}; ${expires};`;
  if (path) {
    cookie += `path=${path};`;
  }
  document.cookie = cookie;
};

const getCookie = (name: string) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const c = cookies[i].split('=');
    if (c[0].trim() === name.trim()) {
      return c[1];
    }
  }
  return null;
};

const getResizedImageLink = (url: string, width: number) => {
  return `https://therecount.com/cdn-cgi/image/width=${width},f=auto/${url}`;
};

const imageUrl = ({
  id,
  width,
  height,
  fit = 'cover',
  gravity = 'auto',
}: {
  id: string;
  width?: number;
  height?: number;
  fit?: string;
  gravity?: string;
}) => {
  return `https://therecount.com/cdn-cgi/image/f=auto,${width ? `width=${2 * width},` : ''}${
    height ? `height=${2 * height},` : ''
  }fit=${fit},gravity=${gravity}/${id}`;
};

const durationToISO8601 = (duration: number) => {
  // This only takes time into account, so any duration below a 24hrs.
  let str = 'PT';

  let remainingSeconds = duration;
  const hours = Math.floor(remainingSeconds / 3600.0);
  remainingSeconds = Math.max(0.0, remainingSeconds - hours * 3600.0);
  const minutes = Math.floor(remainingSeconds / 60.0);
  remainingSeconds = Math.round(Math.max(0.0, remainingSeconds - minutes * 60.0));
  if (hours > 0) {
    str += `${hours}H`;
  }
  if (minutes > 0) {
    str += `${minutes}M`;
  }
  str += `${remainingSeconds}S`;
  return str;
};

const namedComponent = <A>(name: string, comp: A): A => {
  (comp as any).displayName = name;
  return comp;
};

const mergeRefs = (refs: Array<(el: Element) => void | { current: Element }>) => {
  return (el: Element) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(el);
      } else if (ref != null) {
        (ref as any).current = el;
      }
    });
  };
};

/** Used to guarantee a pattern-match is exhaustive */
const exhaustive = (v: never): never => v;

/** Takes a unix timestamp and formats it according to the string */
const formatDateTime = (ts: number, formatStr: string) =>
  dateFormat(new Date(ts * 1000), formatStr);

const commafy = (words: Array<string>) => {
  if (words.length === 2) {
    return `${words[0]} and ${words[1]}`;
  }
  const [first, rest] = splitAt(1, words);
  let result = first[0];
  rest.forEach((word, i) => {
    if (i === rest.length - 1) {
      result += `, and ${word}`;
    } else {
      result += `, ${word}`;
    }
  });
  return result;
};

const wordChar = /[a-zA-Z']/;
/**
 * Truncates text to the specified length making sure to only truncate
 * at a word boundary. The returned text has an ellipsis on the end.
 *
 * @param text Text to be truncated
 * @param limit Maximum allowed length of the text
 * @param opts.reserve extra space to reserve in the truncation case
 */
const truncateText = (
  text: string,
  limit: number,
  { reserve = 0 }: { reserve?: number } = {},
): string => {
  if (text.length <= limit) {
    return text;
  }
  let candidate = limit - 1 - reserve;
  while (wordChar.test(text[candidate])) {
    candidate -= 1;
  }
  return `${text.slice(0, candidate)}…`;
};

const renderText = (text: Array<InlineNode>): string => {
  let ret = '';
  for (const node of text) {
    for (const mark of node.marks ?? []) {
      switch (mark.type) {
        case 'em': {
          ret += '<em>';
          break;
        }
        case 'strong': {
          ret += '<strong>';
          break;
        }
        case 'link': {
          const title = mark.attrs.title ? ` title="${mark.attrs.title}"` : '';
          ret += `<a href="${mark.attrs.href}"${title}>`;
          break;
        }
        default: {
          exhaustive(mark.type);
        }
      }
    }
    ret += node.text;
    for (const mark of reverse(node.marks ?? [])) {
      switch (mark.type) {
        case 'em': {
          ret += '</em>';
          break;
        }
        case 'strong': {
          ret += '</strong>';
          break;
        }
        case 'link': {
          ret += `</a>`;
          break;
        }
        default: {
          exhaustive(mark.type);
        }
      }
    }
  }
  return ret;
};

const renderRichText = (transcript: Array<BlockNode>): string => {
  let ret = '';
  for (const node of transcript) {
    switch (node.type) {
      case 'ParagraphNode': {
        ret += `<p>${renderText(node.content)}</p>`;
        break;
      }
      case 'BlockQuoteNode': {
        ret += `<blockquote>${renderRichText(node.content)}</blockquote>`;
        break;
      }
      default: {
        exhaustive(node);
      }
    }
  }
  return ret;
};

export {
  exhaustive,
  namedComponent,
  mergeRefs,
  padString,
  isExternal,
  durationToString,
  stringToSlug,
  setCookie,
  getCookie,
  getResizedImageLink,
  imageUrl,
  durationToISO8601,
  formatDateTime,
  commafy,
  // react-redux and other major react libraries re-export this
  // and according to internal source it's used extensively at
  // facebook and so there's no real danger of removal or
  // unannounced modification.
  unstable_batchedUpdates as batchUpdates,
  truncateText,
  renderRichText,
};
