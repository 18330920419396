const videoPlayerPrefix = 'video-player';
// import {videoPlayer as videoPlayerDomIds} from '../../../../shared/domIds';
export const videoPlayer = {
  INITIAL_PLAY_BUTTON: `${videoPlayerPrefix}-initial-play-button`,
  DURATION: `${videoPlayerPrefix}-duration`,
  VOLUME_PANEL: `${videoPlayerPrefix}-volume-panel`,
  MOBILE_FULLSCREEN_BUTTON: `mobile-${videoPlayerPrefix}-fullscreen-button`,
  REMIAING_TIME: `${videoPlayerPrefix}-remaining-time`,
  MOBILE_NEXT_BUTTON: `mobile-${videoPlayerPrefix}-next-button`,
  MOBILE_PLAY_BUTTON: `mobile-${videoPlayerPrefix}-play-button`,
  MOBILE_PREVIOUS_BUTTON: `mobile-${videoPlayerPrefix}-previous-button`,
  SETTINGS_BUTTON: `${videoPlayerPrefix}-settings-button`,
  SETTINGS_LIST: `${videoPlayerPrefix}-settings-list`,
  CAPTIONS_OPTION: `${videoPlayerPrefix}-captions-option`,
  QUALITY_ITEM: `${videoPlayerPrefix}-quality-item`,

  DESKTOP_FULLSCREEN_BUTTON: `desktop-${videoPlayerPrefix}-fullscreen-button`,
  DESKTOP_NEXT_BUTTON: `desktop-${videoPlayerPrefix}-next-button`,
  DESKTOP_PLAY_BUTTON: `desktop-${videoPlayerPrefix}-play-button`,
  DESKTOP_PREVIOUS_BUTTON: `desktop-${videoPlayerPrefix}-previous-button`,
};

const videoDetailPrefix = 'video-detail';
export const videoDetail = {
  CONTAINER: `${videoDetailPrefix}-container`,
  VIDEO_INFO: `${videoDetailPrefix}-info`,
  VIDEO_TITLE: `${videoDetailPrefix}-title`,
  SHOW_TITLE: `${videoDetailPrefix}-show-title`,
  VIDEO_DESCRIPTION: `${videoDetailPrefix}-description`,
  VIDEO_DATE: `${videoDetailPrefix}-date`,
};

const socialMediaPrefix = 'social-media';
export const socialMedia = {
  CONTAINER: `${socialMediaPrefix}-container`,
  FACEBOOK_ITEM: `${socialMediaPrefix}-facebook`,
  TWITTER_ITEM: `${socialMediaPrefix}-twitter`,
  CLIPBOARD_ITEM: `${socialMediaPrefix}-clipboard`,
};

const promotionPrefix = 'promotion';
export const promotion = {
  BANNER_MOBILE: `${promotionPrefix}-banner-mobile`,
  PROMOTION_HEADER: `${promotionPrefix}-header`,
  GET_NEWSLETTER: `${promotionPrefix}-get-newsletter`,
  FOLLOW_US: `${promotionPrefix}-follow-us`,
};

const videoListPrefix = 'video-list';
export const videoList = {
  CONTAINER: `${videoListPrefix}-container`,
};

const headerPrefix = 'header';
export const header = {
  LOGO: `${headerPrefix}-LOGO`,
};

const menuPrefix = 'side-menu';
export const menu = {
  HAMBURGER_MENU_OPEN_BUTTON: `${menuPrefix}-HAMBURGER_MENU_OPEN_BUTTON`,
  CLOSE_BUTTON: `${menuPrefix}-CLOSE_BUTTON`,
  ABOUT_US: `${menuPrefix}-ABOUT_US`,
  ITEM_CONTAINER: `${menuPrefix}-ITEM_CONTAINER`,
};
