import React from 'react';
import NextError from 'next/error';
import * as Sentry from '@sentry/node';
import { NextPageContext } from 'next';

import { useHeader } from 'components/Header';
import { useFooter } from 'components/Footer';
import styles from './_error.module.scss';

const errorMessages: { [s: number]: string } = {
  400: 'Oops, what did you do?',
  401: "You don't have access to this page",
  404: "We couldn't find the page you were looking for 🤔",
  410: "This page doesn't exist anymore, sorry 🙁",
  451: "We can't display this page in your country for legal reasons 😬",
};

type MyErrorProps = {
  statusCode?: number;
  hasGetInitialPropsRun?: boolean;
  err?: Error & { statusCode: number };
};
const MyError = ({ statusCode, hasGetInitialPropsRun, err }: MyErrorProps) => {
  const status = statusCode || err?.statusCode || 500;
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/zeit/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
  }
  const Header = useHeader();
  const Footer = useFooter();

  return (
    <>
      <Header />
      <div className={styles.root}>
        <div className={styles.container}>
          <h1>{status}</h1>
          <p>{errorMessages[status] || 'There was an unexpected error 😔'}</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

MyError.getInitialProps = async (ctx: NextPageContext) => {
  const { res, err, asPath } = ctx;
  const statusCode = res ? res.statusCode : err ? err.statusCode : 500;
  const errorInitialProps = await NextError.getInitialProps(ctx);

  if (statusCode !== 404 && err) {
    Sentry.captureException(err);
  }
  if (!err && !res) {
    // If this point is reached, getInitialProps was called without any
    // information about what the error might be. This is unexpected and may
    // indicate a bug introduced in Next.js, so record it in Sentry
    Sentry.captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`));
  }

  return {
    ...errorInitialProps,
    hasGetInitialPropsRun: true,
    err,
    statusCode,
  };
};

export default MyError;
