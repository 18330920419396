import { ReactNode, useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { repeat } from 'ramda';

import Grid from 'components/Grid';
import { Video, Show, Id } from 'types';
import Icon from 'components/Icon';
import useMediaQuery from 'shared/useMediaQuery';
import * as videoStore from 'store/videos';
import { Loadable } from 'store/util';
import { exhaustive } from 'shared/util';
import { VideoTileSkeleton } from 'components/VideoTile';

import styles from './index.module.scss';
import VideoTile from './VideoTile';

interface RecirculationCarouselProps {
  videos: Array<Video> | Loadable<Array<Video>>;
  title: React.ReactNode;
}

const RecirculationCarousel = ({ videos, title }: RecirculationCarouselProps) => {
  const vids = (() => {
    if (Loadable.isLoadable(videos)) {
      if (videos._tag === 'Loaded') {
        return videos.data;
      }
      return null;
    }
    return videos;
  })();

  let items = repeat(<VideoTileSkeleton />, 12);
  if (vids !== null) {
    items = vids.map((v) => (
      <VideoTile key={v.id} displayDate={true} className={styles.tile} video={v} imageWidth={330} />
    ));
  }
  return (
    <div className={styles.recirculationContainer}>
      <Grid container>
        <Grid item sm={12}>
          <h2 className={styles.sectionTitle}>{title}</h2>
        </Grid>
      </Grid>
      <div className={styles.carouselContainer}>
        <Slider items={items} position={0} />
      </div>
    </div>
  );
};

type SliderProps = {
  items: Array<ReactNode>;
  position: number;
};
const Slider = ({ items }: SliderProps) => {
  const [position, setPosition] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(600);
  const isTablet = useMediaQuery(900);
  const disableNext = () => {
    const pos = Math.abs(position);
    if (isTablet) {
      return pos >= Math.floor((items.length - 1) / 4);
    }
    return pos >= Math.floor((items.length - 1) / 6);
  };

  const slideStyle = {
    '--position': position,
    '--col': items.length,
    backgroundColor: 'transparent',
  };
  const itemStyle = {};
  const gridItems = isMobile ? items.slice(0, (position + 1) * 4) : items;

  useEffect(() => {
    setPosition(0);
  }, [isMobile]);

  return (
    <div className={styles.slider}>
      <div className={classNames(styles.buttonGroup, 'hide@sm')}>
        <button
          disabled={position === 0}
          onClick={() => setPosition((p) => p + 1)}
          aria-label="Next"
        >
          <Icon.ArrowLeft color="" />
        </button>{' '}
        <button
          disabled={disableNext()}
          onClick={() => setPosition((p) => p - 1)}
          aria-label="Previous"
        >
          <Icon.ArrowRight color="" />
        </button>
      </div>
      <div className={styles.container} ref={containerRef}>
        <div className={styles.slide} style={slideStyle}>
          {gridItems.map((item, index) => {
            return (
              <div key={`item-${index}`} className={styles.item} style={itemStyle}>
                {item}
              </div>
            );
          })}
        </div>
      </div>
      {(position + 1) * 4 < items.length && (
        <Grid container className={classNames(styles.buttonContainer, 'hide@md', 'hide@lg')}>
          <a className={styles.button} onClick={() => setPosition((p) => p + 1)}>
            Load More
          </a>
        </Grid>
      )}
    </div>
  );
};

const useRecirculationCarousel = (
  show: Show,
  excluded?: Array<Id<'Video'>>,
): React.FunctionComponent<Omit<RecirculationCarouselProps, 'videos' | 'title'>> => {
  const excludedVideos = excluded || [];
  // TODO replace with graphql endpoint that handles logic for # of vids
  const videos = videoStore.getByShow({
    show: show.id,
    limit: 12 + excludedVideos.length,
  });

  const curriedRecirculationCarousel = () => {
    switch (videos._tag) {
      case 'NotLoaded':
      case 'NotFound':
        return null;
      case 'Loaded': {
        const filteredVideos = videos.data
          .filter((v) => {
            return excludedVideos.indexOf(v.id) < 0;
          })
          .slice(0, 12);
        const { title } = show;
        return <RecirculationCarousel videos={filteredVideos} title={title} />;
      }
      default:
        return exhaustive(videos);
    }
  };
  curriedRecirculationCarousel.displayName = 'CurriedRecirculationCarousel';
  return curriedRecirculationCarousel;
};

export { RecirculationCarousel, useRecirculationCarousel };
