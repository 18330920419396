import classNames from 'classnames';
import { useState } from 'react';
import { sortBy } from 'ramda';

import { exhaustive } from 'shared/util';
import * as sectionStore from 'store/sections';
import { Section } from 'types';
import hamburger from 'shared/icons/hamburger-menu.svg';
import closeIcon from 'shared/icons/close-new.svg';
import logo from 'shared/icons/logo-re.svg';

import { FacebookAccount, InstagramAccount, TwitterAccount } from 'components/Social';
import styles from './index.module.scss';

/**
 * @param active The slug of the active section (if any) or 'podcasts'.
 */
type HeaderProps = {
  sections: Array<Section>;
  active?: string;
  showNewsletterButton?: boolean;
};
const Header = ({ sections, active, showNewsletterButton = true }: HeaderProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const navItems = sortBy((s) => s.position, sections).map((s) => ({
    key: s.slug,
    slug: `/section/${s.slug}`,
    title: s.title,
  }));
  /*
  navItems.push({
    key: 'podcasts',
    slug: '/podcasts',
    title: 'Podcasts',
  });
  */
  const nav = (
    <nav className={classNames(styles.nav, 'display@lg')}>
      {navItems.map((item) => {
        const className = item.key === active ? styles.active : '';
        return (
          <a key={item.key} href={`${item.slug}`} className={className}>
            {item.title}
          </a>
        );
      })}
    </nav>
  );

  const mobileNav = (
    <>
      <a
        className={classNames(styles.mobileNavButton, 'hide@lg')}
        aria-label="Open Site Navigation"
        onClick={() => setMenuIsOpen(true)}
      >
        <img src={hamburger.src} width={16} height={12} alt="Menu icon" />
      </a>
      <div className={classNames(styles.navOverlay, 'hide@lg', { hide: !menuIsOpen })} />
      <div className={classNames(styles.mobileNav, 'hide@lg', { hide: !menuIsOpen })}>
        <header>
          <a
            className={styles.closeButton}
            aria-label="Close Site Navigation"
            onClick={() => setMenuIsOpen(false)}
          >
            <img src={closeIcon.src} width={18} height={18} alt="Close menu icon" />
          </a>
          <img src={logo.src} width={35} height={22} alt="The Recount Logo" />
        </header>
        <nav>
          {navItems.map((item) => {
            const className = item.key === active ? styles.active : '';
            return (
              <a key={item.key} href={`${item.slug}`} className={className}>
                {item.title}
              </a>
            );
          })}
        </nav>
        {showNewsletterButton && (
          <a className={styles.mobileCta} href="/newsletter">
            Get the Newsletter
          </a>
        )}
        <div className={styles.social}>
          <InstagramAccount />
          <TwitterAccount />
          <FacebookAccount />
        </div>
      </div>
    </>
  );

  const newsletterCta = (
    <a className={classNames(styles.cta, 'display@lg')} href="/newsletter">
      Get The Newsletter
    </a>
  );

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        {mobileNav}
        <div className={styles.logo}>
          <a href="/">
            <img src="/logo.svg" alt="The Recount" width={160} height={40} />
          </a>
        </div>
        {nav}
        {showNewsletterButton && newsletterCta}
      </div>
    </header>
  );
};

/** Creates a header component with sections already applied */
const useHeader = (): React.FunctionComponent<Omit<HeaderProps, 'sections'>> => {
  const sections = sectionStore.getAll();

  const curriedHeader = (props: Omit<HeaderProps, 'sections'>) => {
    switch (sections._tag) {
      case 'NotLoaded':
      case 'NotFound':
        return null;
      case 'Loaded': {
        return <Header sections={sections.data} {...props} />;
      }
      default:
        return exhaustive(sections);
    }
  };
  curriedHeader.displayName = 'CurriedHeader';
  return curriedHeader;
};

export { Header, useHeader };
