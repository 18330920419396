import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useParams } from 'react-router-dom';
import { config } from 'shared/config';

import * as videoStore from 'store/videos';
import * as sectionStore from 'store/sections';
import { Id, Section, Video } from 'types';
import { formatDateTime, renderRichText } from 'shared/util';
import { Loadable, Loaded } from 'store/util';
import { useHeader } from 'components/Header';
import { useFooter } from 'components/Footer';
import { DisplayAd } from 'components/DisplayAd';
import { VideoPlayer } from 'components/VideoPlayer';
import { RecirculationCarousel } from 'components/RecirculationCarousel';
import Grid from 'components/Grid';
import CopyLinkButton from 'components/CopyLinkButton';
// import TipJar from 'components/TipJar';
import { VideoTile, VideoTileSkeleton } from 'components/VideoTile';
import Error from 'pages/_error.page';

import { Skeleton } from 'components/Skeleton';
import { DefaultMeta } from 'components/Meta';
import { FacebookShare, TwitterShare } from 'components/Social';
import { Meta } from './Meta';
import styles from './index.module.scss';
import DonationPromptBanner from 'components/DonationPromptBanner';

const AD_SLOT = '/22673562521/Responsive_DisplayAds_ATF';
const AD_SLOT_2 = '/22673562521/Responsive_DisplayAds_BTF';

const WatchPageHeader = ({
  video: videoL,
}: {
  video: Loadable<Video<'renditions' | 'tags' | 'sections' | 'show'>>;
}) => {
  const [autoplayS, setAutoplay] = useState(false);
  const params = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null;
  const autoplay = params?.get('autoplay') === 'true' || autoplayS;
  const twitterIntent = Loadable.quickMatch(videoL, '#', (v) => v.shareURL);
  const twitterTitle = Loadable.quickMatch(videoL, '#', (v) => v.title);
  const fbIntent = Loadable.quickMatch(videoL, '#', (v) => `${v.shareURL}`);

  const socialSection = (
    <Grid container alignItems="center">
      <Grid item sm={12} md={12} lg={3}>
        <div className={styles.socialIcons}>
          <CopyLinkButton
            disabled={videoL._tag === 'NotLoaded'}
            clipboardText={Loadable.quickMatch(videoL, '', (v) => v.shareURL)}
          />
          <TwitterShare url={twitterIntent} text={twitterTitle} />
          <FacebookShare url={fbIntent} />
        </div>
      </Grid>
      {/* <Grid item sm={12} md={12} lg={9}>
        <TipJar />
      </Grid> */}
    </Grid>
  );

  useEffect(() => {
    if (autoplay) {
      setAutoplay(true);
      const currentUrl = new URL(window.location.toString());
      const newParams = new URLSearchParams(window.location.search);
      newParams.delete('autoplay');
      currentUrl.search = newParams.toString();
      window.history.replaceState(null, document.title, currentUrl.toString());
    }
  }, []);

  return (
    <Grid container className={styles.headerContainer}>
      <Grid item md={7} lg={8}>
        {Loadable.match(videoL, {
          Loaded: (video) => (
            <VideoPlayer disableAds={!config.adsEnabled} autoplay={autoplay} video={video} />
          ),
          _: () => <Skeleton type="image-lg" />,
        })}
      </Grid>
      <Grid
        container
        direction="column"
        md={5}
        lg={4}
        className={styles.headerContent}
        justifyContent="space-between"
        paddingLeft="var(--padding-watch-header)"
      >
        <Grid item className={styles.topContent}>
          <div className={styles.metaContent}>
            <span className={styles.section}>
              {Loadable.match(videoL, {
                Loaded: (video) => {
                  const section = video.sections[0];
                  return (
                    <a href={`/section/${section ? section.slug : 'politics'}`}>
                      {section ? section.title : 'Politics'}
                    </a>
                  );
                },
                _: () => <Skeleton type="word" />,
              })}
            </span>
            <span className={styles.date}>
              {Loadable.match<Video, React.ReactNode>(videoL, {
                Loaded: (video) => formatDateTime(video.publishedAt ?? 0, 'dddd mm.dd.yy'),
                _: () => <Skeleton type="word" />,
              })}
            </span>
          </div>
          {Loadable.match(videoL, {
            Loaded: (v) => <h1>{v.title}</h1>,
            _: () => <Skeleton type="title" lines={2} />,
          })}
        </Grid>
        <Grid item className={styles.bottomContent}>
          {Loadable.match(videoL, {
            Loaded: (v) => {
              if (v.article.length > 0) {
                return null;
              } else {
                return <p className={styles.description}>{v.description}</p>;
              }
            },
            _: () => <Skeleton type="text" lines={5} />,
          })}
          {Loadable.match(videoL, {
            Loaded: (v) => {
                return socialSection
            },
            _: () => (
              <div className={styles.socialIcons}>
                <CopyLinkButton
                  disabled={videoL._tag === 'NotLoaded'}
                  clipboardText={Loadable.quickMatch(videoL, '', (v) => v.shareURL)}
                />
                <TwitterShare url={twitterIntent} text={twitterTitle} />
                <FacebookShare url={fbIntent} />
              </div>
            ),
          })}
        </Grid>
      </Grid>
      {Loadable.match(videoL, {
        Loaded: (v) => {
          if (v.article.length === 0) {
            return null;
          }
          return (
            <Grid item className={styles.article}>
              <div
                className={styles.articleText}
                dangerouslySetInnerHTML={{
                  __html: renderRichText(v.article),
                }}
              />
            </Grid>
          );
        },
        _: () => null,
      })}
    </Grid>
  );
};

const RelatedContent = ({ video }: { video: Loadable<Video<'show' | 'sections'>> }) => {
  const politicsSectionL = sectionStore.getBySlug('politics');
  const section = Loadable.flatMap(video, (v) =>
    v.sections[0] ? Loaded(v.sections[0]) : politicsSectionL,
  );
  const latest = videoStore.getBySection({
    section: Loadable.map(section, (s) => s.slug),
    limit: 9,
  });

  const latestVideos = Loadable.match(latest, {
    Loaded: (vs) =>
      vs
        .filter((v) => v.id !== Loadable.quickMatch(video, null, (v2) => v2.id))
        .slice(0, 8)
        .map((v, i) => (
          <div key={v.id} className={i > 3 ? 'hide@sm' : ''}>
            <VideoTile video={v} imageWidth={330} reverse />
          </div>
        )),
    NotFound: () => null as React.ReactNode, // This occurs for wire videos
    NotLoaded: () => (
      <>
        <Grid item sm={12} md={6} lg={3}>
          <VideoTileSkeleton />
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <VideoTileSkeleton />
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <VideoTileSkeleton />
        </Grid>
        <Grid item sm={12} md={6} lg={3}>
          <VideoTileSkeleton />
        </Grid>

        <Grid item md={6} lg={3} className="hide@small">
          <VideoTileSkeleton />
        </Grid>
        <Grid item md={6} lg={3} className="hide@small">
          <VideoTileSkeleton />
        </Grid>
        <Grid item md={6} lg={3} className="hide@small">
          <VideoTileSkeleton />
        </Grid>
        <Grid item md={6} lg={3} className="hide@small">
          <VideoTileSkeleton />
        </Grid>
      </>
    ),
  });

  if (latest._tag === 'NotFound') {
    return null; // Recount Wire videos
  }
  return (
    <div className={styles.relatedContentContainer}>
      <div className={styles.relatedContent}>
        <h2 className={styles.relatedContentTitle}>
          {Loadable.match<Section, React.ReactNode>(section, {
            Loaded: (s) => s.slug,
            _: () => <Skeleton type="word" />,
          })}
        </h2>
        <div className={styles.relatedContentVideos}>{latestVideos}</div>
      </div>
    </div>
  );
};

const WatchPage = () => {
  const router = useRouter();
  const params = useParams<'id' | 'slug'>();
  // Statically guaranteed to be a string which is the runtime rep of Id
  const id: Id<'Video'> = (params.id || router.query.id) as Id<'Video'>;
  // Statically guaranteed to be a string
  const slug: string = (params.slug || router.query.slug) as string;

  const Header = useHeader();
  const Footer = useFooter();

  const videoL = videoStore.get(id);
  // TODO replace with graphql endpoint that handles logic for # of vids
  const relatedVideosL = videoStore.getByShow({
    show: Loadable.map(videoL, (v) => v.show.id),
    limit: 13,
  });

  useEffect(() => {
    window.scrollTo(0, 0); // for client-based routing
    Loadable.forEach(videoL, (v) => {
      if (v.slug !== slug) {
        window.history.replaceState(null, '', `/watch/${v.slug}/${id}`);
      }
    });
  }, [id]);

  if (videoL._tag === 'NotFound') {
    return <Error statusCode={404} />;
  }

  const videoId = Loadable.getOrElse(
    null,
    Loadable.map(videoL, (v) => v.id),
  );
  const relatedVideos = Loadable.map(relatedVideosL, (vs) =>
    vs.filter((v) => v.id !== videoId).slice(0, 12),
  );
  const recircTitle = Loadable.getOrElse<React.ReactNode>(
    <Skeleton type="word" />,
    Loadable.map(videoL, (v) => v.show.title),
  );

  return (
    <React.Fragment key={id}>
      {Loadable.match(videoL, {
        Loaded: (v) => <Meta video={v} />,
        NotLoaded: () => null,
        NotFound: () => <DefaultMeta />,
      })}
      {Loadable.match(videoL, {
        Loaded: (v) => (
          <DisplayAd
            headerSlot={true}
            video={v}
            adSlot={AD_SLOT}
            currentPath={`/watch/${slug}/${id}`}
          />
        ),
        NotLoaded: () => null,
        NotFound: () => null,
      })}
      <Header active={slug} />
      <WatchPageHeader video={videoL} />
      <DonationPromptBanner />
      <RecirculationCarousel videos={relatedVideos} title={recircTitle} />
      {Loadable.match(videoL, {
        Loaded: (v) => (
          <DisplayAd video={v} adSlot={AD_SLOT_2} currentPath={`/watch/${slug}/${id}`} />
        ),
        NotLoaded: () => null,
        NotFound: () => null,
      })}
      <RelatedContent video={videoL} />
      <Footer />
    </React.Fragment>
  );
};

export default WatchPage;
