const markAttrs = `
href,
title
`;

const mark = `
type,
attrs { ${markAttrs} }
`;

const inlineNode = `
text,
marks { ${mark} },
`;

const paragraphNode = `
type: __typename,
content { ${inlineNode} },
`;

const blockQuoteNode = `
type: __typename,
content { ${paragraphNode} },
`;

const blockNode = `
... on ParagraphNode { ${paragraphNode} }
... on BlockQuoteNode { ${blockQuoteNode} }
`;

const rendition = `
  url,
  type,
  width,
  height
`;

const tag = `
  text,
  internalTag
`;
const show = `
  id,
  title,
  slug,
  description,
  thumbnailURL,
  tags { ${tag} },
  posterURL
`;
const section = `
  id,
  title,
  slug,
  imageTreatment,
  position,
`;
const video = `
  id,
  title,
  description,
  duration, 
  thumbnailURL,
  url,
  slug,
  shareURL,
  publishedAt,
  disableAds,
  transcript,
  article { ${blockNode} },
  tags { ${tag} },
  show { ${show} },
  renditions { ${rendition} },
  sections { ${section} }
`;
const liveVideo = `
  id,
  duration,
  location,
  publishedAt,
  shareURL,
  slug,
  thumbnailURL,
  title,
  url,
`;
const podcastShow = `
  id,
  name,
  description,
  coverArt,
  slug,
  acastShowId,
  iheartradioShowId,
  subscribeLinks {
    type,
    title,
    url,
  },
  hosts {
    bio,
    name,
    imageURL,
  },
  showId
`;

const podcastEpisode = `
  id,
  description,
  title,
  createdAt,
  coverArt,
  publishedAt,
  transcript { ${blockNode} },
  slug,
  acastEpisodeId,
  audioURL,
  episodeNumber,
  seasonNumber,
  podcastShowId,
  podcastShow { ${podcastShow} },
`;

export { rendition, tag, show, video, liveVideo, section, podcastShow, podcastEpisode };
