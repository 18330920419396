import { getDataFromTree } from '@apollo/client/react/ssr';
import { onError } from '@apollo/client/link/error';
import React from 'react';
import type { NextPageContext } from 'next';
import App, { AppContext } from 'next/app';
import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
} from '@apollo/client';

import { config } from './config';
import logging from './logging';

let globalClient: ApolloClient<NormalizedCacheObject> | null = null;

interface NextPageContextWithApollo extends NextPageContext {
  apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
  apolloState: NormalizedCacheObject;
  ctx: NextPageContextApp;
}

type NextPageContextApp = NextPageContextWithApollo & AppContext;

type NextComponent<T> = React.ComponentType<T> & {
  getInitialProps?: (args: { ctx?: NextPageContext }) => Promise<T>;
};
const withApollo = <T,>(Comp: NextComponent<T>): NextComponent<T> => {
  const WithApollo: any = (props: any) => {
    let { apolloClient } = props;
    if (!apolloClient && typeof window !== 'undefined') {
      globalClient = createApolloClient(props.apolloState);
      apolloClient = globalClient;
    }
    return (
      <ApolloProvider client={apolloClient}>
        <Comp {...props} />
      </ApolloProvider>
    );
  };
  WithApollo.getInitialProps = async (appCtx: NextPageContextApp) => {
    const { AppTree } = appCtx;

    if (!appCtx.apolloClient) {
      appCtx.apolloClient = createApolloClient();
    }

    // Run wrapped getInitialProps methods
    let pageProps: any = {};
    if (Comp.getInitialProps) {
      pageProps = await Comp.getInitialProps(appCtx);
    } else {
      pageProps = await App.getInitialProps(appCtx);
    }

    // When redirecting, the response is finished.
    // No point in continuing to render
    if (appCtx.res && appCtx.res.finished) {
      return pageProps;
    }

    if (typeof window === 'undefined') {
      try {
        const props: any = {
          apolloClient: appCtx.apolloClient,
          ...pageProps,
        };
        await getDataFromTree(
          <ApolloProvider client={appCtx.apolloClient}>
            <AppTree {...props} />)
          </ApolloProvider>,
        );
      } catch (e: any) {
        logging.error('Error while running `getDataFromTree`', e);
      }
    }

    return {
      ...pageProps,
      apolloClient: appCtx.apolloClient || null,
      apolloState: appCtx.apolloClient.extract(),
    };
  };
  return WithApollo;
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      logging.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  if (networkError) logging.error(`[Network error]: ${networkError}`);
});

const createApolloClient = (
  initialState: NormalizedCacheObject = {},
): ApolloClient<NormalizedCacheObject> => {
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([
      errorLink,
      new HttpLink({
        uri: config.graphqlEnpoint, // Server URL (must be absolute)
        fetch: typeof window === 'undefined' ? require('node-fetch') : window.fetch,
      }),
    ]),
    ssrForceFetchDelay: 100,
    cache: new InMemoryCache().restore(initialState),
    connectToDevTools: true,
  });
  (client as any).toJSON = () => null;
  return client;
};

export { withApollo };
