import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import videojs, { VideoJsPlayer } from 'video.js';
import classNames from 'classnames';

import playIcon from 'shared/icons/play.svg';
import pauseIcon from 'shared/icons/pause.svg';
import styles from './index.module.scss';
// import 'components/Content/VideoPlayer/PlayStatusIndicator/PlayStatusIndicator.css'

const vjsComponent = videojs.getComponent('Component');

type PlayStatusIndicatorProps = {
  player: VideoJsPlayer;
};
type PlayStatusIndicatorState = {
  animated: boolean;
  iconSrc: string;
  iconClass: string;
  visibility: boolean;
  timeout: NodeJS.Timeout | null;
};
class PlayStatusIndicator extends Component<PlayStatusIndicatorProps, PlayStatusIndicatorState> {
  constructor(props: PlayStatusIndicatorProps) {
    super(props);
    this.state = { animated: false, iconClass: '', iconSrc: '', visibility: false, timeout: null };
  }

  componentDidMount() {
    const { player } = this.props;
    player.on('play', () => {
      if (player.currentTime() !== 0) {
        this.triggerAnimation(playIcon.src, styles.play);
      }
    });

    player.on('pause', () => {
      if (player.currentTime() !== player.duration()) {
        this.triggerAnimation(pauseIcon.src, 'pause');
      }
    });
  }

  triggerAnimation = (icon: string, className: string) => {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
    const timeout = setTimeout(() => {
      this.setState({ animated: false, iconSrc: icon, iconClass: className, visibility: false });
    }, 500);
    this.setState({
      animated: true,
      iconSrc: icon,
      iconClass: className,
      visibility: true,
      timeout,
    });
  };

  componentWillUnmount() {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
  }

  render() {
    const { iconSrc, iconClass, animated, visibility } = this.state;
    const animatedClass = animated ? styles.animated : styles.notAnimated;
    const visibilityClass = visibility ? styles.visible : styles.invisible;
    return (
      <div className={classNames(styles.playStatusIndicator, animatedClass, visibilityClass)}>
        <div className={styles.tableCenter}>
          <div className={styles.iconContainer}>
            <img src={iconSrc} className={classNames(styles.icon, iconClass)} />
          </div>
        </div>
      </div>
    );
  }
}

class VJSPlayStatusIndicator extends vjsComponent {
  constructor(player: VideoJsPlayer, options: videojs.ComponentOptions) {
    super(player, options);
    this.mount = this.mount.bind(this);
    player.ready(() => {
      this.mount();
    });

    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    ReactDOM.render(<PlayStatusIndicator player={this.player()} />, this.el());
  }
}

vjsComponent.registerComponent('PlayStatusIndicator', VJSPlayStatusIndicator);
