import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import videojs, { VideoJsPlayButton } from 'video.js';

import { videoPlayer as videoPlayerDomIds } from 'shared/domIds';

import styles from './index.module.scss';

const vjsComponent = videojs.getComponent('Component');

type PlayButtonProps = {
  container: Element;
  toggle: VideoJsPlayButton;
  isMobile: boolean;
};
const PlayButton = ({ container, toggle, isMobile }: PlayButtonProps) => {
  const togglePlay = (evt: Event) => {
    if (evt.target === container) {
      if (toggle) {
        const el = toggle.el();
        if (el instanceof HTMLElement) {
          el.click();
        }
      }
    }
  };

  if (container && container.classList) {
    // add our root style to our parent designated container div.
    if (!container.classList.contains(styles.root)) {
      container.classList.add(styles.root);
    }
    if (isMobile) {
      container.classList.add(styles.isMobile);
    }
  }

  useEffect(() => {
    if (container) {
      container.appendChild(toggle.el());

      // listen for click events to delegate them to the toggle.
      container.removeEventListener('click', togglePlay); // remove any old event
      container.addEventListener('click', togglePlay);

      toggle.el().id = isMobile
        ? videoPlayerDomIds.MOBILE_PLAY_BUTTON
        : videoPlayerDomIds.DESKTOP_PLAY_BUTTON;
    }
  }, []);

  return null;
};

type VJSPlayButtonOptions = {
  playToggle: VideoJsPlayButton;
  isMobile: boolean;
};
class VJSPlayButton extends vjsComponent {
  playToggle: VideoJsPlayButton;

  isMobile: boolean;

  constructor(player: videojs.Player, options: videojs.ComponentOptions & VJSPlayButtonOptions) {
    super(player, options);
    this.mount = this.mount.bind(this);
    this.playToggle = options.playToggle;
    this.isMobile = options.isMobile;
    player.ready(() => {
      this.mount();
    });
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    // this.el is an empty div.
    ReactDOM.render(
      <PlayButton toggle={this.playToggle} isMobile={this.isMobile} container={this.el()} />,
      this.el(),
    );
  }
}

vjsComponent.registerComponent('playButton', VJSPlayButton);

export default VJSPlayButton;
