import React, { useEffect, useState } from 'react';
import type videojs from 'video.js';
import { QualityLevel } from 'videojs-contrib-quality-levels';

import QualityItem from './QualityItem';

import styles from './index.module.scss';

type IQuality = { quality: QualityLevel; index: number };

type QualityMenuProps = {
  player: videojs.Player;
  forceQualityLevels?: boolean;
};
const QualityMenu = ({ player, forceQualityLevels = false }: QualityMenuProps) => {
  const [qualityLevels, setQualityLevels] = useState<Array<IQuality> | null>(null);

  const onQualityLevelAdded = () => {
    if (
      player.qualityLevels() &&
      ((player.tech({ IWillNotUseThisInPlugins: true }) as any).hls || forceQualityLevels)
    ) {
      const indexedQualities = Array.from(player.qualityLevels()).reduce(
        (acc: Array<{ quality: QualityLevel; index: number }>, quality, index) => {
          // let's disable 240p
          if (quality.height !== 240) {
            acc.push({ quality, index });
          } else {
            player.qualityLevels()[index].enabled = false;
          }
          return acc;
        },
        [],
      ); // we map them to their respective index so they know what to select on click.
      setQualityLevels(indexedQualities);
    } else {
      setQualityLevels(null);
    }
  };

  useEffect(() => {
    player.ready(() => {
      player.qualityLevels().on('addqualitylevel', onQualityLevelAdded);
    });
    return () => player.qualityLevels().off('addqualitylevel', onQualityLevelAdded);
  }, [player]);

  if (!qualityLevels) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={styles.menuTitle}>Quality</div>
      <div className={styles.separator} />
      {qualityLevels?.map((q) => (
        <QualityItem key={q.index} qualityItem={q} player={player} />
      ))}
    </React.Fragment>
  );
};

export default QualityMenu;
