import Icon from './Icon';

const TwitterShare = ({ url, text }: { url: string; text?: string }) => (
  <a
    href={`https://twitter.com/intent/tweet?url=${url}${text ? `&text=${text}` : ''}`}
    target="_blank"
    rel="noreferrer"
    aria-label="Share on Twitter"
  >
    <Icon.Twitter color="" />
  </a>
);

const FacebookShare = ({ url }: { url: string }) => (
  <a
    href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
    target="_blank"
    rel="noreferrer"
    aria-label="Share on Facebook"
  >
    <Icon.Facebook color="" />
  </a>
);

const InstagramAccount = () => (
  <a href="https://www.instagram.com/TheRecount/" aria-label="The Recount on Instagram">
    <Icon.Instagram />
  </a>
);
const TwitterAccount = () => (
  <a href="https://twitter.com/therecount" aria-label="The Recount on Twitter">
    <Icon.Twitter />
  </a>
);
const FacebookAccount = () => (
  <a href="https://www.facebook.com/TheRecount/" aria-label="The Recount on Facebook">
    <Icon.Facebook />
  </a>
);

export { TwitterShare, FacebookShare, InstagramAccount, TwitterAccount, FacebookAccount };
