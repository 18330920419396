import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import videojs from 'video.js';

import { durationToString } from 'shared/util';
import { videoPlayer as videoPlayerDomIds } from 'shared/domIds';
import logging from 'shared/logging';
import Icon from 'components/Icon';

import styles from './index.module.scss';

const vjsComponent = videojs.getComponent('Component');

type BigPlayButtonProps = {
  player: videojs.Player;
  container: Element;
};
const BigPlayButton = ({ player, container }: BigPlayButtonProps) => {
  const [duration, setDuration] = useState(0);
  const onClick = () => {
    player.play()?.catch((err) => logging.error(err));
  };
  if (container && container.classList) {
    // add our root style to our parent designated container div.
    if (!container.classList.contains(styles.root)) {
      container.classList.add(styles.root);
      container.classList.add(styles.hidden);
    }

    if (duration > 0 && container.classList.contains(styles.hidden)) {
      container.classList.remove(styles.hidden);
    }

    container.removeEventListener('click', onClick);
    container.addEventListener('click', onClick);
  }
  useEffect(() => {
    player.on('durationchange', () => {
      setDuration(Math.floor(player.duration()));
    });
  }, []);

  const durationString = durationToString(duration);
  return (
    <>
      <Icon.Play />
      <span id={videoPlayerDomIds.DURATION} className={styles.duration}>
        {durationString}
      </span>
    </>
  );
};

class VJSBigPlayButton extends vjsComponent {
  constructor(player: videojs.Player, options: videojs.ComponentOptions) {
    super(player, options);
    this.mount = this.mount.bind(this);
    player.ready(() => {
      this.mount();
    });

    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    ReactDOM.render(<BigPlayButton player={this.player()} container={this.el()} />, this.el());
  }
}

vjsComponent.registerComponent('BigPlayButton', VJSBigPlayButton);

export default VJSBigPlayButton;
