import classNames from 'classnames';

import Icon from 'components/Icon';
import { FunctionComponent } from 'react';
import styles from './index.module.scss';

const Skeleton: FunctionComponent<{
  type: 'word' | 'text' | 'title' | 'image' | 'image-lg';
  lines?: number;
}> = ({ type, lines = 1 }) => {
  const classes = classNames(styles.loading, styles[type]);
  let svg: null | JSX.Element = null;
  if (type === 'image-lg') {
    svg = <Icon.Spinner />;
  }

  const textLines = Array.from(Array(lines));

  return (
    <>
      {textLines.map((n, i) => (
        <div key={i} className={classes}>
          {svg}
        </div>
      ))}
    </>
  );
};

export { Skeleton };
