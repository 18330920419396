import React from 'react';
import type { NextPageContext } from 'next';
import App, { AppContext } from 'next/app';
import cookie from 'cookie';

type CookieManager = [(name: string) => string | null, (name: string, value: string) => void];
const CookiesContext = React.createContext<CookieManager>([
  () => {
    throw new Error('Cookie Manager is not initialized');
  },
  () => {
    throw new Error('Cookie Manager is not initialized');
  },
]);

type NextComponent<T> = React.ComponentType<T> & {
  getInitialProps?: (args: { ctx?: NextPageContext }) => Promise<T>;
};
const withCookies = <T,>(Comp: NextComponent<T>): NextComponent<T> => {
  const WithCookies: any = (props: any) => {
    const cookies = props.cookies || {}; // TODO there's a bug around getInitialProps that needs to be resolved.
    const getCookie = (name: string) => {
      const value = cookies[name];
      return value === undefined ? null : value;
    };
    const setCookie = (() => {
      if (typeof window === 'undefined') {
        return (name: string, value: string) => {
          cookies[name] = value;
          // TODO Next is flushing headers before we get here
          // preventing us from setting server-side cookies
          // props.setHeader('Set-Cookie', cookie.serialize(name, value, { secure: true }));
        };
      }
      return (name: string, value: string) => {
        document.cookie = cookie.serialize(name, value, { secure: true, path: '/' });
      };
    })();
    return (
      <CookiesContext.Provider value={[getCookie, setCookie]}>
        <Comp {...props} />
      </CookiesContext.Provider>
    );
  };
  WithCookies.getInitialProps = async (appCtx: AppContext) => {
    const inAppContext = Boolean(appCtx.ctx);
    const { ctx } = appCtx;
    if (ctx?.res) {
      // server-side setCookie isn't working so set all the feature switch cookies here
      Object.keys(ctx.query).forEach((key) => {
        if (key.startsWith('f-')) {
          if (ctx.query[key] === 'true') {
            ctx?.res?.setHeader(
              'Set-Cookie',
              cookie.serialize(`feature-${key.replace(/^f-/, '')}`, 'true', {
                secure: true,
                path: '/',
              }),
            );
          }
          if (ctx.query[key] === 'false') {
            ctx?.res?.setHeader(
              'Set-Cookie',
              cookie.serialize(`feature-${key.replace(/^f-/, '')}`, 'false', {
                secure: true,
                path: '/',
              }),
            );
          }
        }
      });
    }

    // Run wrapped getInitialProps methods
    let pageProps: any = {};
    if (Comp.getInitialProps) {
      pageProps = await Comp.getInitialProps(appCtx);
    } else if (inAppContext) {
      pageProps = await App.getInitialProps(appCtx);
    }

    let cookies = {};
    if (typeof window !== 'undefined') {
      cookies = cookie.parse(document.cookie);
    } else if (ctx?.req?.headers.cookie) {
      cookies = cookie.parse(ctx?.req?.headers.cookie);
    }

    return {
      ...pageProps,
      cookies,
      setHeader: ctx?.res?.setHeader,
    };
  };
  return WithCookies;
};

/**
 * Example usage:
 * ```
 * const [getCookie, setCookie] = useCookies();
 *
 * const myCookie = getCookie('myCookie');
 * setCookie('myCookie', 'foo');
 */
const useCookies = () => React.useContext(CookiesContext);

export { withCookies, useCookies };
