import React, { useEffect } from 'react';
import Head from 'next/head';
import { logPageView } from 'shared/ga';

import { durationToISO8601, imageUrl } from 'shared/util';
import { Video } from 'types';
import { baseMeta, twitterMeta, openGraphMeta, jsonLd, defaults } from 'components/Meta';
import { config } from 'shared/config';

const structuredData = (video: Video<'tags' | 'show'>) => {
  const tagsText = video.tags.map((tag) => JSON.stringify(tag.text));
  const tags = tagsText.join(', ');
  const publishedDate = new Date(video.publishedAt * 1000).toISOString();
  return {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'VideoObject',
      '@id': video.shareURL,
      name: video.title,
      description: video.description || video.title,
      thumbnailUrl: imageUrl({ id: video.thumbnailURL, width: 1200 }),
      uploadDate: new Date(video.publishedAt * 1000).toISOString(),
      contentUrl: video.url,
      duration: durationToISO8601(video.duration),
    },
    url: video.shareURL,
    headline: video.title,
    thumbnailUrl: imageUrl({ id: video.thumbnailURL, width: 1280 }),
    image: {
      '@type': 'ImageObject',
      url: imageUrl({ id: video.thumbnailURL, width: 1280 }),
      width: 1280,
      height: 720,
    },
    datePublished: publishedDate,
    dateModified: publishedDate,
    publisher: {
      '@type': 'Organization',
      name: 'The Recount',
      logo: {
        '@type': 'ImageObject',
        url: imageUrl({
          id: defaults.logoURL,
          width: 1280,
        }),
      },
    },
    articleSection: video.show.title,
    description: video.description,
    keywords: [tags],
  };
};

type MetaProps = {
  video: Video<'renditions' | 'show' | 'tags' | 'sections'>;
};
const Meta = ({ video }: MetaProps) => {
  // Add mp4 renditions for twitter native player.
  const rendition = video.renditions.find((r) => r.type === 'mp4');
  const twitterPlayer = rendition ? (
    <>
      <meta name="twitter:player" content={rendition.url} />
      <meta name="twitter:player:width" content={`${rendition.width || 360}`} />
      <meta name="twitter:player:height" content={`${rendition.height || 200}`} />
    </>
  ) : null;

  const { title } = video;
  const description =
    video.show.id === config.wireShow && video.transcript
      ? video.transcript
      : video.description || video.title;
  const publishedDate = new Date(video.publishedAt * 1000).toISOString();
  const keywords = video.tags.map((tag) => tag.text).join(', ');
  const ampUrl = `https://therecount.com/watch/amp/${video.slug}/${video.id}`;

  useEffect(() => {
    logPageView({
      pathname: window.location.pathname,
      section: video.show.title,
      postId: video.id,
      publicationDate: publishedDate,
      tags: video.tags.map((tag) => tag.text).join(', '),
      vertical: video.sections[0] ? video.sections[0].title : 'Politics',
    });
  }, []);

  // TODO: Add ids to the .env
  return (
    <Head>
      {baseMeta({ title, description, keywords })}
      {twitterMeta({ image: imageUrl({ id: video.thumbnailURL, width: 1500 }), title })}
      {twitterPlayer}
      {openGraphMeta({
        type: 'article',
        image: { id: video.thumbnailURL, width: 1280, height: 720 },
        title,
        description,
        url: video.shareURL,
      })}

      <meta property="article:section" content={video.show.title} />
      <link rel="amphtml" href={ampUrl} />

      <meta property="article:published_time" content={publishedDate} />
      <meta property="article:modified_time" content={publishedDate} />

      {jsonLd(structuredData(video))}
    </Head>
  );
};

export { Meta };
