import React, { useEffect } from 'react';
import Head from 'next/head';

import { imageUrl } from 'shared/util';
import { logPageView } from 'shared/ga';

/**
 * Meta components need to be referenced as functions
 * Because Next.js's <Head> component does not work with
 * react components.
 */

const fbAppId = '2404898236214343';
const bannerId = 'https://files.therecount.com/site/recount-banner.jpeg';

const defaults = {
  bannerURL: imageUrl({
    id: 'bannderId',
    width: 1280,
  }),
  logoURL: imageUrl({
    id: 'https://files.therecount.com/site/logo.jpg',
    width: 1280,
  }),
  title: 'The Recount',
  description: `The Recount reinvents video journalism for the platforms reshaping the news:
          streaming and social media. We cut through noise, rejecting stale narratives and calling
          out big lies. Never wasting your time or insulting your intelligence. Driving dialogue
          instead of mindless division. Featuring voices that challenge your perspective. In an era
          of information overload, alternative facts, and rampant conspiracy theories, it’s never
          been more vital to illuminate what matters most: the truth.`,
  type: 'Website',
  siteURL: 'https://therecount.com/',
  keywords:
    'politics, john heilemann, the daily recount, rewind, recap 2020, election, debate, the center ring, trump, this week in bullshit',
};

type BaseMetaArgs = {
  title?: string;
  description?: string;
  keywords?: string;
};

const structuredData = ({
  keywords = defaults.keywords,
  title = defaults.title,
  description = defaults.description,
}: BaseMetaArgs) => ({
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: title,
  alternateName: '',
  publisher: {
    '@type': 'Organization',
    name: defaults.title,
    logo: {
      '@type': 'ImageObject',
      url: defaults.logoURL,
    },
  },
  url: defaults.siteURL,
  sameAs: [
    'https://facebook.com/therecount',
    'https://twitter.com/therecount',
    'https://youtube.com/therecount',
  ],

  image: {
    '@type': 'ImageObject',
    url: defaults.bannerURL,
    width: 1280,
    height: 450,
  },
  description,
  keywords,
});

type TwitterMetaArgs = {
  card?: string;
  image?: string;
  title?: string;
};
const twitterMeta = ({
  card = 'summary_large_image',
  image = defaults.bannerURL,
  title = defaults.title,
}: TwitterMetaArgs) => (
  <>
    <meta name="twitter:card" content={card} />
    <meta name="twitter:site" content="@therecount" />
    <meta name="twitter:site:id" content="1087380412089987077" />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:title" content={title} />
  </>
);

type OpenGraphMetaArgs = {
  type?: string;
  image?: {
    id: string;
    width: number;
    height: number;
  };
  title?: string;
  description?: string;
  url?: string;
};
const openGraphMeta = ({
  type = 'website',
  image = { id: bannerId, width: 1280, height: 450 },
  title = defaults.title,
  description = defaults.description,
  url = defaults.siteURL,
}: OpenGraphMetaArgs) => (
  <>
    <meta property="fb:app_id" content={fbAppId} />
    <meta property="og:type" content={type} />
    <meta property="og:site_name" content={defaults.title} />
    <meta
      property="og:image"
      content={imageUrl({
        id: image.id,
        width: image.width,
      })}
    />
    <meta property="og:image:width" content={`${image.width}`} />
    <meta property="og:image:height" content={`${image.height}`} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={url} />
  </>
);

const jsonLd = (json: any) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(json),
    }}
  />
);

const baseMeta = ({
  keywords = defaults.keywords,
  title = defaults.title,
  description = defaults.description,
}: BaseMetaArgs) => (
  <>
    <title>{title}</title>
    <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
    <meta name="apple-itunes-app" content="app-id=1476451822" />
    <meta name="referrer" content="always" />
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
  </>
);

const DefaultMeta = ({
  keywords = defaults.keywords,
  title = defaults.title,
  description = defaults.description,
}: BaseMetaArgs) => {
  useEffect(() => {
    logPageView({
      pathname: window.location.pathname,
    });
  }, []);

  // TODO: Add ids to the .env
  return (
    <Head>
      {baseMeta({ keywords, title, description })}
      {twitterMeta}
      {openGraphMeta}
      {jsonLd(structuredData({ keywords, title, description }))}
    </Head>
  );
};

export { DefaultMeta, twitterMeta, openGraphMeta, jsonLd, baseMeta, defaults };
