import React, { Component } from 'react';
import videojs from 'video.js';
import type { Video, VideoRendition } from 'types';
import classNames from 'classnames';
import 'videojs-contrib-quality-levels';
import Head from 'next/head';

import { getCookie, setCookie, getResizedImageLink } from 'shared/util';
import logging from 'shared/logging';
import { GaEvent, logEvent } from 'shared/ga';
import { config } from 'shared/config';

import './PlayStatusIndicator';
// registers itself to the player
import './LoadingSpinner';
import './BigPlayButton';
import './ErrorHandlingDisplay';
import Controls from './Controls';
// registers itself to the player

// import styles from './VideoPlayer.module.scss';
import styles from './index.module.scss';
import 'video.js/dist/video-js.min.css';
import { useDonationBannerContext } from 'shared/donationBannerContext';

const throwError = (err: Error) => {
  throw err;
};

if (config.adsEnabled) {
  // @ts-ignore
  require('videojs-ima');
  // @ts-ignore
  require('videojs-contrib-ads');
}

type VideoPlayerProps = {
  video: Video<'tags' | 'renditions' | 'show' | 'sections'>;
  autoplay: boolean;
  disableAds?: boolean;
  preserveAspectRatio?: boolean;
};

type _VideoPlayerProps = VideoPlayerProps & {
  showDialogue: () => void;
};

class _VideoPlayer extends Component<_VideoPlayerProps> {
  videoRef: React.RefObject<HTMLVideoElement>;

  containerRef: React.RefObject<HTMLDivElement>;

  isPlaying: boolean;

  played: boolean;

  player: videojs.Player | null;

  playerIsReady: boolean;

  imaInitialized: boolean;

  canplay: boolean;

  currentSrc: Array<videojs.Tech.SourceObject> | null;

  lastPlayedPercentage: number;

  lastPlayedTime: number;

  showDialogue: () => void;

  constructor(props: _VideoPlayerProps) {
    super(props);
    this.videoRef = React.createRef();
    this.containerRef = React.createRef();
    this.isPlaying = false;
    this.played = false;
    this.player = null;
    this.playerIsReady = false;
    this.imaInitialized = false;
    this.lastPlayedPercentage = 0;
    this.lastPlayedTime = 0;
    this.canplay = false;
    this.currentSrc = null;
    this.showDialogue = props.showDialogue;
  }

  static defaultProps = {
    disableAds: false,
  };

  loadVideo = (video: Video<'renditions'>) => {
    const { player } = this;
    if (!player) {
      return;
    }
    const { thumbnailURL, url } = video;
    if (!this.playerIsReady) {
      return;
    }

    this.canplay = false;

    // obtain the sources and use the mp4 rendition as backup if available.
    const { renditions } = video;
    const sources: Array<videojs.Tech.SourceObject> = [{ src: url, type: 'application/x-mpegURL' }];

    if (renditions.length > 0) {
      const mp4RenditionSrc = renditions.reduce(
        (acc: videojs.Tech.SourceObject | null, rendition: VideoRendition) => {
          if (rendition.type === 'mp4') {
            return { src: rendition.url, type: 'video/mp4' };
          }
          return acc;
        },
        null,
      );
      if (mp4RenditionSrc) {
        sources.push(mp4RenditionSrc);
      }
    }

    this.currentSrc = sources;
    player.src(sources);
    player.poster(getResizedImageLink(thumbnailURL, 1280));
    this.setupAds();
  };

  setupAds = () => {
    if (typeof window === 'undefined') {
      return;
    } // we don't want the server to get any ads when SSR happens.
    const { player } = this;
    // init the ima sdk if necessary.
    if (!this.imaInitialized) {
      if (typeof window.google === 'undefined') {
        videojs.log('[VideoPlayer] google not defined, probably an AdBlock :(');
      } else {
        try {
          if (player !== null) {
            player.ima({ adTagUrl: this.getAdURL(), adLabel: '' });
            this.imaInitialized = true;
          } else {
            videojs.log(
              '[VideoPlayer] an error ocurred while initializing the ad: tried to set up ads before player initialized',
            );
          }
        } catch (error) {
          videojs.log('[VideoPlayer] an error ocurred while initializing the ad: ', error);
        }
      }
    }
  };

  getAdURL = () => {
    const { video } = this.props;
    if (config.videoAdUrl && video) {
      const sectionTitle = video.sections[0] ? video.sections[0].title : 'Politics';
      const videoTags = video.tags.map((tag) => tag.text).join(',');
      const params = new URLSearchParams({
        custom_params: `section=${sectionTitle}&show=${video.show.title}&video_id=${video.id}&video_tags=${videoTags}`,
        description_url: video.shareURL,
      });
      return config.videoAdUrl.concat('&', params.toString());
    }
    return '';
  };

  play = () => {
    if (!this.player) {
      return;
    }

    this.player
      .play()
      ?.then(() => {
        videojs.log('[VideoPlayer] play() successful on first try');
      })
      .catch((error) => {
        videojs.log('[VideoPlayer] play(): some other error occurred! =>', error);
      });
  };

  enterFullScreenIfNeeded = (player: videojs.Player) => {
    // we won't try to enter fullscreen until the player is ready and the video can play to avoid InvalidStateError in webkitEnterFullscreen
    if (!this.playerIsReady || !this.canplay || !player.supportsFullScreen()) {
      return;
    }

    if (window.screen.orientation) {
      // for most mobile browsers
      if (
        window.screen.orientation.type === 'landscape-primary' ||
        window.screen.orientation.type === 'landscape-secondary'
      ) {
        try {
          player.requestFullscreen();
        } catch (err) {
          player.exitFullscreen();
          videojs.log('[VideoPlayer] failed to enter FS on orientation change.', err);
        }
      }
    } else if (
      window.orientation === 90 ||
      window.orientation === -90 ||
      window.orientation === 270
    ) {
      try {
        player.requestFullscreen();
      } catch (err) {
        player.exitFullscreen();
        videojs.log('[VideoPlayer][Safari] failed to enter FS on orientation change.', err);
      }
    }
  };

  handleError = () => {
    // the reason we're setting a timeout is to ensure the error finished propagating
    // for analytics for example since we need to delete the error from the player.
    setTimeout(() => {
      const { player } = this;
      if (!player) {
        logging.error('[VideoPlayer][Error]', 'Unknown player error');
        return;
      }

      player.pause();
      player.error(null);
      const cT = player.currentTime();
      player.src(player.currentSrc());
      const playback = player.play();
      if (playback) {
        playback.then(() => player.currentTime(cT));
      }

      logging.error('[VideoPlayer][Error]', player.error(), cT);
    }, 0);
  };

  componentDidMount = () => {
    const { video } = this.props;

    const options = {
      nativeControlsForTouch: false,
      suppressNotSupportedError: true,
      resizeManager: false,
      errorDisplay: false,
      controlBar: {
        children: undefined,
      },
      userActions: {
        hotkeys: true,
      },
      html5: {
        hls: {
          overrideNative: true,
        },
      },
    };

    // Create the videojs player
    const player =
      this.videoRef.current !== null
        ? videojs(this.videoRef.current, options)
        : throwError(new Error('Could not construct video.js player without element'));

    // Get the stored volume if we have any
    const playerVolumeCookieName = 'player_volume';
    if (this.videoRef?.current) {
      const storedVolume = getCookie(playerVolumeCookieName);
      let volume = storedVolume === null ? 0.5 : parseFloat(storedVolume);
      if (Number.isNaN(volume)) {
        volume = 0.5;
      }
      this.videoRef.current.volume = volume;
    }

    // Setup extra controls
    player.addChild('PlayStatusIndicator', {});
    player.addChild('ErrorHandlingDisplay', {});
    const controlBar = player.getChild('ControlBar');
    if (controlBar) {
      controlBar.addChild(new Controls(player));
    }

    // Listen to player events
    player.handleHotkeys = (evt: KeyboardEvent) => {
      evt.preventDefault(); // avoid scrolling while playing/pausing video.
      if (evt.code === 'Space') {
        // spacebar
        if (this.isPlaying && this.videoRef.current) {
          this.videoRef.current.pause();
        } else if (this.videoRef.current) {
          this.videoRef.current.play();
        }
      }
      player.trigger('spacebarpressed', evt);
    };

    player.ready(() => {
      this.player = player;
      this.playerIsReady = true;
      // player.httpSourceSelector()

      // react to orientation
      window.addEventListener('orientationchange', () => {
        this.enterFullScreenIfNeeded(player);
      });

      // load and play the video
      this.loadVideo(video); // cause the first render happens in the server and is not going to have the player yet.
    });

    player.on(['waiting', 'pause'], () => {
      this.isPlaying = false;
    });
    player.on('playing', () => {
      this.isPlaying = true;
    });

    player.on('canplay', () => {
      this.canplay = true;
    });
    player.on('volumechange', () => {
      setCookie(playerVolumeCookieName, `${this.videoRef.current?.volume}`);
    });

    const makePayload = (
      category: string,
      action: string,
      init: Omit<GaEvent, 'category' | 'action'> = {},
    ): GaEvent => {
      const quality = (() => {
        const qualityLevels = player.qualityLevels();
        const { selectedIndex } = qualityLevels;
        if (selectedIndex >= 0) {
          return `${qualityLevels[selectedIndex].height}p`;
        }
        return null;
      })();
      const payload: GaEvent = {
        ...init,
        category,
        action,
        section: video.show.title,
        postId: video.id,
        playtime: player.currentTime().toString(),
        vertical: video.sections[0] ? video.sections[0].title : 'Politics',
      };
      if (quality) {
        payload.quality = quality;
      }
      return payload;
    };

    // play status events
    player.on('play', () => {
      this.showDialogue();
      // To avoid events firing twice due to ads.
      if (!player.hasStarted()) {
        return;
      }
      logEvent(makePayload('HTML5 Video', this.played ? 'Played video' : 'Initial video start'));
      if (!this.played) {
        this.played = true;
      }
    });

    player.on('pause', () => {
      if (!player.hasStarted()) {
        return;
      } // To avoid firing if autoplay fails.
      logEvent(makePayload('HTML5 Video', 'Paused video'));
    });

    // ads events
    player.on(
      'ads-manager',
      (response: { adsManager: { addEventListener: (evt: string, cb: () => void) => void } }) => {
        const { adsManager } = response;
        adsManager.addEventListener('start', () => {
          logEvent(makePayload('Video Ad', 'Ad started'));
        });
        adsManager.addEventListener('complete', () => {
          logEvent(makePayload('Video Ad', 'Ad finished'));
        });
        adsManager.addEventListener('skip', () => {
          logEvent(makePayload('Video Ad', 'Ad skipped'));
        });
      },
    );

    // error events
    player.on('error-recovery-failed', () => {
      const error = player.error();
      if (error) {
        logEvent(makePayload('HTML5 Video', 'Error recovery failed', { label: error.message }));
      }
    });
    player.on('error-recovery-success', (evt: string, data: { data: string }) => {
      if (data.data) {
        logEvent(makePayload('HTML5 Video', 'Error recovery success', { label: data.data }));
      }
    });

    player.on('error', () => {
      const error = player.error();
      if (error) {
        logEvent(makePayload('HTML5 Video', 'Error ', { label: error.message }));
      }
    });

    player.on('timeupdate', () => {
      const currentTime = player.currentTime();
      const percentage = Math.floor((currentTime / video.duration) * 100);

      if (this.lastPlayedPercentage !== percentage) {
        this.lastPlayedPercentage = percentage;
        if (percentage % 5 === 0 && percentage > 0) {
          logEvent(makePayload('HTML5 Video', `${percentage}%`));
        }
      }

      if (this.lastPlayedTime !== Math.floor(currentTime)) {
        this.lastPlayedTime = Math.floor(currentTime);
        if ([3, 5, 10, 15, 30].indexOf(this.lastPlayedTime) > -1) {
          logEvent(makePayload('HTML5 Video', `${this.lastPlayedTime} seconds`));
        }
      }
    });
  };

  componentWillUnmount() {
    if (this.player) {
      this.player.off([
        'play',
        'error',
        'ads-manager',
        'error-recovery-failed',
        'error-recovery-success',
        'loadedmetadata',
        'waiting',
        'pause',
        'playing',
        'volumechange',
        'canplay',
        'durationchange',
        'readystate',
        'timeupdate',
      ]);
      // Broken with React at the moment since it remove the DOM nodes
      // Causing subsequent renders to fail.
      // this.player.dispose();
      this.player = null;
    }
  }

  render() {
    const { video, autoplay, preserveAspectRatio } = this.props;
    const { videoRef } = this;
    this.loadVideo(video);
    const aspect = preserveAspectRatio === undefined ? true : preserveAspectRatio;
    const wrapperClass = classNames(
      {
        [styles.preserveAspect]: aspect,
      },
      'player-wrapper',
    );

    const googleIMASDK = config.adsEnabled ? (
      <Head>
        <script src="//imasdk.googleapis.com/js/sdkloader/ima3.js" async={true}></script>
      </Head>
    ) : null;

    return (
      <div className={wrapperClass} ref={this.containerRef}>
        {googleIMASDK}
        <video autoPlay={autoplay} playsInline className="video-js" ref={videoRef} controls></video>
      </div>
    );
  }
}

function VideoPlayer(props: VideoPlayerProps) {
  const { showDialogue } = useDonationBannerContext();
  return <_VideoPlayer {...props} showDialogue={showDialogue} />;
}

export { VideoPlayer };
