/* eslint-disable no-console */
import { config } from 'shared/config';

const logLevel = config.appEnv === 'development' ? 'debug' : 'info';

const debug = (...params: Array<any>) => {
  if (logLevel === 'debug') {
    console.log(params);
  }
};
const info = (...params: Array<any>) => console.log(params);
const error = (...params: Array<any>) => console.error(params);
export default {
  debug,
  info,
  error,
};
