import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import videojs from 'video.js';

import { videoPlayer as videoPlayerDomIds } from 'shared/domIds';
import logging from 'shared/logging';

import styles from './index.module.scss';

const vjsComponent = videojs.getComponent('Component');

type FullscreenButtonProps = {
  container: Element;
  toggle: videojs.FullscreenToggle;
  isMobile: boolean;
};
const FullscreenButton = ({ container, toggle, isMobile }: FullscreenButtonProps) => {
  const toggleFS = () => {
    // pass the event to the toggle button
    logging.debug('toggle FS');
    if (toggle) {
      const el = toggle.el();
      if (el instanceof HTMLElement) {
        el.click();
      }
    }
  };
  if (container && container.classList) {
    // add our root style to our parent designated container div.
    if (!container.classList.contains(styles.root)) {
      container.classList.add(styles.root);
    }
    if (isMobile) {
      container.classList.add(styles.isMobile);
    }
  }
  useEffect(() => {
    if (container) {
      // listen for click events to delegate them to the toggle.
      container.removeEventListener('click', toggleFS); // remove any old event
      container.addEventListener('click', toggleFS);

      container.id = isMobile
        ? videoPlayerDomIds.MOBILE_FULLSCREEN_BUTTON
        : videoPlayerDomIds.DESKTOP_FULLSCREEN_BUTTON;
    }
  }, []);

  return <img onClick={toggleFS} src="/icons/fullscreen.svg" />;
};

type FullscreenButtonOptions = {
  id: string;
  isMobile: boolean;
  fullscreenToggle: videojs.FullscreenToggle;
};
class VJSFullscreenButton extends vjsComponent {
  isMobile: boolean;

  fullscreenToggle: videojs.FullscreenToggle;

  constructor(player: videojs.Player, options: videojs.ComponentOptions & FullscreenButtonOptions) {
    super(player, options);
    this.mount = this.mount.bind(this);
    this.id = () => options.id;
    this.isMobile = options.isMobile;
    this.fullscreenToggle = options.fullscreenToggle;
    player.ready(() => {
      this.mount();
    });
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    // this.el is an empty div.
    ReactDOM.render(
      <FullscreenButton
        isMobile={this.isMobile}
        toggle={this.fullscreenToggle}
        container={this.el()}
      />,
      this.el(),
    );
  }
}

vjsComponent.registerComponent('fullscreenButton', VJSFullscreenButton);

export default VJSFullscreenButton;
