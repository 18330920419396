import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import videojs, { VideoJsPlayer } from 'video.js';

import retryIcon from 'public/icons/refresh.svg';
import logging from 'shared/logging';

import styles from './index.module.scss';

const vjsComponent = videojs.getComponent('Component');

const ERROR_RECOVER_ATTEMPTS_THRESHOLD = 3;
const DEFAULT_ERROR_MESSAGE = 'There was a problem while playing the video.';

type ErrorHandlingDisplayProps = {
  player: VideoJsPlayer;
  container: Element;
};
const ErrorHandlingDisplay = ({ player, container }: ErrorHandlingDisplayProps) => {
  const recoverAttempts = useRef(0);
  const currentTimeAtError = useRef<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  if (container && container.classList) {
    if (!container.classList.contains(styles.root)) {
      container.classList.add(styles.root);
    }
  }

  const onError = () => {
    recoverAttempts.current += 1;
    setTimeout(() => {
      // we're doing it inside a timeout to allow for the error event to propagate
      // that way we don't affect the analytics since we may need to clear the error.

      if (recoverAttempts.current >= ERROR_RECOVER_ATTEMPTS_THRESHOLD) {
        // display the error
        const err = player.error();
        if (err) {
          player.pause();
          setError(DEFAULT_ERROR_MESSAGE);
          player.trigger('error-recovery-failed');
        }
      } else {
        // try to recover
        const pError = player.error();
        player.error(null);
        if (currentTimeAtError.current === null) {
          currentTimeAtError.current = player.currentTime();
        }
        player.src(player.currentSrc());
        player.currentTime(currentTimeAtError.current);
        player
          .play()
          ?.then(() => {
            player.trigger('error-recovery-success', { data: pError ? pError.message : '' });
            player.currentTime(currentTimeAtError.current ?? 0);
            currentTimeAtError.current = null;
            recoverAttempts.current = 0;

            setError(null);
          })
          .catch(() => {
            logging.error('[ErrorHandlingDisplay][onError][play.catch]');
          });
      }
    }, 0);
  };

  const onReload = () => {
    recoverAttempts.current = 0;
    currentTimeAtError.current = null;
    player.pause();
    setError(null);
    player.error(null);
    player.src(player.currentSrc());

    player
      .play()
      ?.then(() => {})
      .catch(() => {
        logging.error('[ErrorHandlingDisplay][onError][play.catch]');
      });
  };

  useEffect(() => {
    player.on('error', onError);
    return () => player.off('error', onError);
  }, []);

  return (
    <div className={`${styles.container} ${error ? styles.active : styles.inactive}`}>
      <div className={styles.errorMessage}>{error}</div>
      <div className={styles.retryContainer}>
        <img className={styles.retryButton} src={retryIcon.src} onClick={onReload} />
      </div>
    </div>
  );
};

class VJSErrorHandlingDisplay extends vjsComponent {
  constructor(player: VideoJsPlayer, options: videojs.ComponentOptions) {
    super(player, options);
    this.mount = this.mount.bind(this);
    player.ready(() => {
      this.mount();
    });

    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  mount() {
    ReactDOM.render(
      <ErrorHandlingDisplay player={this.player()} container={this.el()} />,
      this.el(),
    );
  }
}

vjsComponent.registerComponent('ErrorHandlingDisplay', VJSErrorHandlingDisplay);

export default VJSErrorHandlingDisplay;
