import { loadStripe } from '@stripe/stripe-js';
import { config } from 'shared/config';

export const stripeTip = async (redirectUrl: string) => {
  let stripePromise: any = null;

  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(config.stripePublishableKey as string);
    }
    return stripePromise;
  };

  const stripe = await getStripe();

  const response = await fetch('/api/checkout-sessions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${config.stripeSecretKey}`,
    },
    body: JSON.stringify({
      redirectUrl,
    }),
  });

  const checkoutSession = await response.json();
  const result = await stripe.redirectToCheckout({
    sessionId: checkoutSession.id,
  });

  if (result.error) {
    console.error(result.error);
  }
};
