import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import type videojs from 'video.js';
import { QualityLevel, QualityLevelList } from 'videojs-contrib-quality-levels';

import { videoPlayer as videoPlayerDomIds } from 'shared/domIds';

import styles from './index.module.scss';

type IQuality = { quality: QualityLevel; index: number };
type QualityItemProps = {
  player: videojs.Player;
  qualityItem: IQuality;
};
const QualityItem = ({ player, qualityItem }: QualityItemProps) => {
  const [selected, setSelected] = useState(false);
  const selectedRef = useRef(selected);

  const onQualityLevelChange = (evt: QualityLevelList) => {
    const isSelected = evt.selectedIndex === qualityItem.index;
    if (selectedRef.current !== isSelected) {
      // avoid unnecessary renders
      selectedRef.current = isSelected;
      setSelected(isSelected);
    }
  };
  const changeQuality = () => {
    const qualityLevels = player.qualityLevels();

    for (let i = 0; i < qualityLevels.length; i += 1) {
      qualityLevels[i].enabled = i === qualityItem.index;
    }
  };
  useEffect(() => {
    // Type for these handlers is wrong in the type definitions
    const oqlc = onQualityLevelChange as unknown as (_: Event) => void;
    player.qualityLevels().on('change', oqlc);
    return () => player.qualityLevels().off('change', oqlc);
  }, []);

  const clazz = classNames(styles.root, videoPlayerDomIds.QUALITY_ITEM, {
    [styles.selected]: selected,
  });

  return (
    <div className={clazz} onClick={changeQuality}>
      {qualityItem.quality.height}
      <span>p</span>
    </div>
  );
};

export default QualityItem;
