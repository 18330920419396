// TODO inline old Iconic code into this file.
import ReactGA from 'react-ga4';

import type { DataLayerObject } from 'types/window';
import { config } from 'shared/config';

const dimensions = {
  section: 'dimension2',
  tags: 'dimension3',
  publicationDate: 'dimension4',
  postId: 'dimension7',
  autoplay: 'dimension8',
  playtime: 'dimension9',
  quality: 'dimension10',
  vertical: 'dimension11',
};

const init = () => {
  if (config.gaId) {
    ReactGA.initialize(config.gaId);
  }
};

const clearDimensions = (payload: { [key: string]: string }) => {
  const emptyDimensions: { [key: string]: null } = {};
  Object.keys(payload).forEach((key) => {
    emptyDimensions[key] = null;
  });
  if (Object.keys(emptyDimensions).length > 0) {
    ReactGA.set({
      ...emptyDimensions,
    });
  }
};

export type GaEvent = {
  action: string;
  label?: string;
  value?: string;
  category?: string;
} & Partial<typeof dimensions>;

export type GaPageViewEvent = {
  pathname: string;
} & Partial<typeof dimensions>;

const logPageView = (event: GaPageViewEvent): void => {
  const payload: { [key: string]: string } = {};
  for (const [key, value] of Object.entries(event)) {
    const dimension = (dimensions as any)[key];
    if (key !== 'pathname' && dimension) {
      payload[dimension] = value;
    }
  }
  ReactGA.set({ page: event.pathname, ...payload });
  ReactGA.send({ hitType: 'pageview', page: event.pathname });
};

const logEvent = (event: GaEvent, clearDimensionOnSend: boolean = true): void => {
  const mappedDimensions: { [key: string]: string } = {};
  for (const [key, value] of Object.entries(event)) {
    const dimension = (dimensions as any)[key];
    if (key !== 'action' && key !== 'label' && dimension) {
      mappedDimensions[dimension] = value;
    }
  }
  const { category, action, label, value } = event;

  // check if we have any optional params
  type OptionalParams = { eventLabel?: string; eventValue?: string };
  const optionalParams: OptionalParams = {};
  if (label) {
    optionalParams.eventLabel = label;
  }
  if (value) {
    optionalParams.eventValue = value;
  }

  // set dimensions if not empty
  if (Object.keys(mappedDimensions).length > 0) {
    ReactGA.set({
      ...mappedDimensions,
    });
  }

  // send the event
  const payload: DataLayerObject = {
    hitType: 'event',
    eventCategory: category || null,
    eventAction: action,
    ...optionalParams,
  };
  ReactGA.send(payload);

  // if GTM is available, send there too
  if (window.dataLayer) {
    window.dataLayer.push(payload);
  }

  // clear out the dimensions we just set to avoid sending them in unrelated events.
  if (clearDimensionOnSend) {
    clearDimensions(mappedDimensions);
  }
};

export { init, logPageView, logEvent };
