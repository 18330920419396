import { useEffect } from 'react';
import styles from './index.module.scss';
import type { Video } from 'types';

type DisplayAdProps = {
  adSlot: string;
  currentPath: string;
  video?: Video<'tags' | 'renditions' | 'show' | 'sections'>;
  headerSlot?: boolean;
};

const DisplayAd = ({ adSlot, currentPath, video, headerSlot }: DisplayAdProps) => {
  const adId = `recount-ad-${currentPath}-${adSlot}`;
  useEffect(() => {
    window.googletag = window.googletag || { cmd: [] };
    window.googletag.cmd.push(function () {
      const googletag = window.googletag;
      googletag.pubads().setSafeFrameConfig({ sandbox: false });

      const candidateTag = googletag
        .pubads()
        .getSlots()
        .find((slot: { getSlotElementId: () => string }) => slot.getSlotElementId() === adId);
      if (candidateTag) {
        googletag.display(adId);
        googletag.pubads().refresh();
      } else {
        const largeSizes = headerSlot
          ? [
              [970, 250],
              [728, 250],
              [728, 90],
            ]
          : [
              [300, 250],
              [970, 250],
              [728, 250],
              [728, 90],
            ];

        const mediumSizes = headerSlot
          ? [
              [728, 90],
              [728, 250],
            ]
          : [
              [300, 250],
              [728, 90],
              [728, 250],
            ];
        const mapping = googletag
          .sizeMapping()
          .addSize([1000, 100], largeSizes)
          .addSize([750, 100], mediumSizes)
          .addSize(
            [320, 100],
            [
              [300, 250],
              [320, 50],
            ],
          )
          .build();
        googletag.pubads().setCentering(true);
        const googleAd = googletag
          .defineSlot(
            adSlot,
            [
              [300, 250],
              [728, 90],
              [728, 250],
              [970, 250],
              [320, 50],
            ],
            adId,
          )
          .defineSizeMapping(mapping)
          .addService(googletag.pubads());
        if (video) {
          const sectionTitle = video.sections[0] ? video.sections[0].title : 'Politics';
          const videoTags = video.tags.map((tag) => tag.text).join(',');
          googleAd
            .setTargeting('section', sectionTitle)
            .setTargeting('show', video.show.title)
            .setTargeting('video_id', video.id)
            .setTargeting('video_tags', videoTags);
        }
        googleAd.setTargeting('path', currentPath);
        googletag.pubads().enableVideoAds();
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
        googletag.display(adId);
      }
    });
  }, [currentPath]);

  const containerClassName = headerSlot
    ? `${styles.container} ${styles.headerSlot}`
    : styles.container;

  return (
    <div key={currentPath} className={containerClassName}>
      {!headerSlot && <h2>ADVERTISEMENT</h2>}
      <div className={styles.adWrapper}>
        <div id={adId}></div>
      </div>
    </div>
  );
};

export { DisplayAd };
